import React, {useCallback, useEffect, useState} from "react";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    Badge,
    Button,
    Card,
    EmptyState,
    Frame,
    Icon,
    IndexTable,
    Modal,
    Page,
    Select,
    TextField,
    Toast
} from "@shopify/polaris";
import {useHistory} from "react-router";
import ListPagination from "../components/ListPagination";
import {SearchMajor} from "@shopify/polaris-icons";
import {useDebounce} from "use-debounce";
import {format} from "date-fns";

const LIST_TASKS = gql`
    query GetTasks($limit: Int, $offset: Int, $search: TaskSearch) {
        tasks(limit: $limit, offset: $offset, search: $search) {
            tasks {
                id
                name
                status
                created_at
                change_start
                change_end
                confirmed_variant_count
            }

            total_count
        }
    }`;

const DELETE_TASK = gql`
    mutation DeleteTask($taskID: String!) {
        delete_task(task_id: $taskID) {
            id
        }
    }
`

const TASKS_LIST_LIMIT = 10;

const calculateTaskItemNumber = (pageNumber, offset, listLength) => {
    let itemNumber;
    if (offset === 0) {
        itemNumber = listLength;
    } else {
        itemNumber = (pageNumber - 1) * TASKS_LIST_LIMIT + listLength;
    }
    return itemNumber;
};

function renderStatus(task) {
    switch (task.status) {
        case "ready":
            return <Badge status="info">Awaiting Activation</Badge>
        case "pending":
            return <Badge status="warning" progress="partiallyComplete">Applying Changes</Badge>
        case "active":
            return <Badge status="info">Active</Badge>
        case "completed":
            return <Badge status="success" progress="complete">Complete</Badge>
        case "completing":
            return <Badge status="success" progress="partiallyComplete">Completing...</Badge>
        case "completion-failed":
            return <Badge status="critical" progress="incomplete">Completion Failed</Badge>
        case "cancelled":
            return <Badge status="critical" progress="incomplete">Cancelled</Badge>
        case "rolled-back":
            return <Badge status="warning" progress="incomplete">Rolled Back</Badge>
        case "rolling-back":
            return <Badge status="warning" progress="incomplete">Rolling Back...</Badge>
        case "rollback-failed":
            return <Badge status="critical" progress="incomplete">Rollback Failed</Badge>
        case "failed":
            return <Badge status="critical" progress="incomplete">Failed</Badge>
        default:
            return <Badge>{task.status}</Badge>
    }
}

function TaskList() {
    const [tasksList, setTasksList] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasError, setHasError] = useState(false);
    const [taskCount, setTaskCount] = useState(0);
    const [taskItemNumber, setTaskItemNumber] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
    const [statusFilter, setStatusFilter] = useState();

    const [deleteConfirm, setDeleteConfirm] = useState(null);

    const history = useHistory();

    const [loadTasks, loadTasksOpt] = useLazyQuery(LIST_TASKS, {
        variables: {
            limit: TASKS_LIST_LIMIT,
            offset: offset,
            search: {
                title_contains: debouncedSearchQuery,
                status_equals: statusFilter,
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted({tasks}) {
            setTasksList(tasks ? tasks.tasks : []);
            setTaskCount(tasks.total_count);
            setTaskItemNumber(calculateTaskItemNumber(pageNumber, offset, tasks.tasks.length));
        },
        onError(e) {
            console.error("load page error", e);
            setHasError(true);
        },
    });

    const [loadNextPageTasks] = useLazyQuery(LIST_TASKS, {
        variables: {
            limit: TASKS_LIST_LIMIT,
            offset: offset + TASKS_LIST_LIMIT,
            search: {
                title_contains: debouncedSearchQuery,
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted({tasks}) {
            setHasNextPage(tasks ? tasks.tasks.length > 0 : false);
        },
        onError(e) {
            console.error("load next page error", e);
            setHasError(true);
        },
    });

    const {loading} = useQuery(LIST_TASKS, {
        variables: {
            limit: TASKS_LIST_LIMIT,
            offset: offset,
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted({tasks}) {
            setTasksList(tasks.tasks);
            setTaskCount(tasks.total_count);
            setTaskItemNumber(calculateTaskItemNumber(pageNumber, offset, tasks.tasks.length));
            loadNextPageTasks();
        },
        onError(e) {
            console.error("first load error", e);
            setHasError(true);
        },
    });

    const [deleteTask] = useMutation(DELETE_TASK, {
        fetchPolicy: "network-only",
    });

    const onDelete = async (taskId) => {
        setDeleteConfirm(taskId);
    }

    const handleNextPage = useCallback(() => {
        setOffset(offset + TASKS_LIST_LIMIT);
        loadTasks();
        loadNextPageTasks();
        setPageNumber(pageNumber + 1);
    }, [offset, pageNumber, setOffset, loadTasks, loadNextPageTasks, setPageNumber]);

    const handlePrevPage = useCallback(() => {
        if (offset >= TASKS_LIST_LIMIT) {
            setOffset(offset - TASKS_LIST_LIMIT);
        } else {
            setOffset(0);
        }
        loadTasks();
        loadNextPageTasks();
        setPageNumber(pageNumber - 1);
    }, [offset, pageNumber, setOffset, loadTasks, loadNextPageTasks, setPageNumber]);

    useEffect(async () => {
        setOffset(0);
        setPageNumber(1);
        await loadTasks();
        await loadNextPageTasks();
    }, [debouncedSearchQuery, statusFilter]);

    let rowMarkup = null;

    if (tasksList.length > 0) {
        rowMarkup = tasksList.map((task, idx) => (
            <IndexTable.Row id={task.id} key={task.id} position={idx}>

                {/* Price Adjustment Name */}
                <IndexTable.Cell>
                    <span style={{
                        cursor: "pointer"
                    }} onClick={() => history.push(`/tasks/${task.id}`)}>{task.name}</span>
                </IndexTable.Cell>

                {/* Number of products */}
                <IndexTable.Cell>
                    {task.confirmed_variant_count}
                </IndexTable.Cell>

                {/* Effective Date */}
                <IndexTable.Cell>
                    {task.change_start ? format(new Date(task.change_start), "yyyy-MM-dd hh:mm:ss a") : ""}
                </IndexTable.Cell>

                {/* Expiry Date */}
                <IndexTable.Cell>
                    {task.change_end ? format(new Date(task.change_end), "yyyy-MM-dd hh:mm:ss a") : ""}
                </IndexTable.Cell>

                {/* Created Date */}
                <IndexTable.Cell>
                    {task.created_at ? format(new Date(task.created_at), "yyyy-MM-dd hh:mm:ss a") : ""}
                </IndexTable.Cell>

                {/* Status */}
                <IndexTable.Cell>
                    {renderStatus(task)}
                </IndexTable.Cell>

                {/* Edit */}
                <IndexTable.Cell>
                    <Button onClick={() => history.push(`/tasks/${task.id}`)}>Edit</Button>
                </IndexTable.Cell>

                <IndexTable.Cell>
                    <Button destructive onClick={() => onDelete(task.id)}>Delete</Button>
                </IndexTable.Cell>
            </IndexTable.Row>
        ))
    }

    const hasPrevPage = offset > 0;
    const pagination = <ListPagination
        hasPrevious={hasPrevPage}
        hasNext={hasNextPage}
        handlePrevious={handlePrevPage}
        handleNext={handleNextPage}
        pageNumber={pageNumber}
        itemNumber={taskItemNumber}
        count={taskCount}
    />

    const filterByStatusOptions = [
        {value: "__clear", label: "No Filter"},
        {value: "ready", label: "Awaiting Activation"},
        {value: "pending", label: "Applying Changes"},
        {value: "active", label: "Active"},
        {value: "completing", label: "Completing..."},
        {value: "completion-failed", label: "Completion Failed"},
        {value: "completed", label: "Complete"},
        {value: "cancelled", label: "Cancelled"},
        {value: "rolling-back", label: "Rolling Back..."},
        {value: "rolled-back", label: "Rolled Back"},
        {value: "rollback-failed", label: "Rollback Failed"},
        {value: "failed", label: "Failed"},
    ]

    const body = <Card>
        <Card.Section>
            <div style={{
                display: "flex"
            }}>
                <div style={{
                    flex: 1,
                    marginRight: 5
                }}>
                    <TextField
                        label="Filter by name"
                        labelHidden
                        value={searchQuery}
                        onChange={setSearchQuery}
                        prefix={<Icon source={SearchMajor}/>}
                        placeholder={"Filter by Name"}
                        autoComplete="off"
                    />
                </div>
                <div style={{
                    flex: 1
                }}>
                    <Select onChange={(v) => {
                        if (v === "__clear") {
                            setStatusFilter(null);
                        } else {
                            setStatusFilter(v)
                        }
                    }} value={statusFilter} labelHidden placeholder="Filter By Status" label="Filter by Status"
                            options={filterByStatusOptions}/>
                </div>
            </div>

            <IndexTable resourceName={{singular: "task", plural: "tasks"}}
                        loading={loading || loadTasksOpt.loading}
                        selectable={false}
                        emptyState={
                            <EmptyState
                                image="data:image/svg+xml,%3csvg width='60' height='60' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M41.87 24a17.87 17.87 0 11-35.74 0 17.87 17.87 0 0135.74 0zm-3.15 18.96a24 24 0 114.24-4.24L59.04 54.8a3 3 0 11-4.24 4.24L38.72 42.96z' fill='%238C9196'/%3e%3c/svg%3e"
                                heading="You don't have any tasks"
                                action={{
                                    content: "Create Task",
                                    onAction: () => {
                                        history.push('/create-task');
                                    }
                                }}
                            />
                        }
                        headings={[
                            {title: "Task Name"},
                            {title: "Products"},
                            {title: "Effective Date"},
                            {title: "Expiry Date"},
                            {title: "Created"},
                            {title: "Status"},
                        ]} itemCount={tasksList ? tasksList.length : 0}>
                {rowMarkup}
            </IndexTable>
            {pagination}
        </Card.Section>
    </Card>

    const toastMarkup = hasError ? (
        <Toast content="Failed to load tasks." error onDismiss={() => setHasError(false)} duration={4500}/>
    ) : null;

    return <Frame>
        <Page title="Tasks"
              fullWidth
              primaryAction={{
                  content: "Create Task",
                  onAction: () => {
                      history.push('/create-task');
                  }
              }}>
            <Modal open={deleteConfirm !== null} onClose={() => setDeleteConfirm(null)}
                   secondaryActions={{
                       content: "Cancel",
                       onAction() {
                           setDeleteConfirm(null);
                       }
                   }}
                   primaryAction={{
                       content: "Confirm",
                       destructive: true,
                       async onAction() {
                           try {
                               await deleteTask({
                                   variables: {
                                       taskID: deleteConfirm,
                                   }
                               });

                               await loadTasks();
                               setDeleteConfirm(null);
                           } catch (e) {
                               alert(`${e && e.message}`)
                           }
                       }
                   }}
                   title="Are you sure you want to delete this task?">
                <Modal.Section>
                    <p>
                        You will not be able to undo this action
                    </p>
                </Modal.Section>
            </Modal>
            {body}
            {toastMarkup}
        </Page>
    </Frame>
}

export default TaskList;
