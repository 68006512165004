import React, {useCallback, useEffect, useRef, useState} from "react";
import {Banner, Button, Card, IndexTable, Pagination, Tag, Thumbnail} from "@shopify/polaris";
import SearchRule, {NewEmptyRule} from "./SearchRule";
import {gql, useLazyQuery} from "@apollo/client";
import './ProductSearch.css';
import {useDebouncedCallback} from "use-debounce";

const GET_PRODUCTS = gql`
    query GetProducts($count: Int!, $where: SearchGroup, $cursor: String, $task_id: String!) {
        shopify_products(count: $count, where: $where, cursor: $cursor, task_id: $task_id) {
            products {
                id
                image_url
                price
                name
                already_added
                variants {
                    id
                    sku
                    barcode
                }
            }

            next_page_cursor
        }
    }`;

function singleCollectionOnlySearch(rules) {
    return rules.find(r => r.subRules === null || r.subRules === undefined) === undefined;
}

function processRules(rules) {
    const where = [];
    let searchRule = "MATCH_ALL";

    const allCollections = singleCollectionOnlySearch(rules);

    for (const rule of rules) {
        const w = {};

        if (rule.value) {
            w[rule.field] = {
                [rule.type]: rule.value
            };

            // If the rule has sub-rules, add them to the list. If the rules array only has 1 entry, don't care about sub rules
            // If the backend receives a query where the only argument is a collection, it will use a different query method
            if (rule.subRules && rule.collectionSearchMode && !allCollections) {
                searchRule = rule.collectionSearchMode;

                rule.subRules.forEach((sr) => {
                    const w2 = {};

                    if (sr.value && sr.type && sr.field) {
                        w2[sr.field] = {
                            [sr.type]: sr.value,
                        }
                    }

                    where.push(w2);
                })
            }

            where.push(w);
        }
    }

    return {
        fields: where,
        search_mode: searchRule,
    };
}

function ProductSearch({onAddToList, task}) {
    const [rules, setRules] = useState([]);
    const [pageCursors, setPageCursors] = useState([]);
    const isFirstRender = useRef(true);
    const [addedImmediate, setAddedImmediate] = useState([]);

    const [getProducts, {loading, error, data}] = useLazyQuery(GET_PRODUCTS, {
        variables: {
            count: 15,
        },
        fetchPolicy: 'network-only',
    });

    let products = [];
    let hasNextPage = false;
    let hasPreviousPage = pageCursors.length > 0;
    let pageNumber = pageCursors.length + 1;
    let nextPageCursor;

    const collectionCount = rules.filter(r => r.field === "collection").length;

    if (data) {
        products = data.shopify_products.products;

        if (data.shopify_products.next_page_cursor) {
            hasNextPage = true;
            nextPageCursor = data.shopify_products.next_page_cursor;
        }
    }

    const debouncedLoad = useDebouncedCallback((args) => {
        getProducts(args);
        setPageCursors([]);
    }, 1000);

    const handleRemoveRule = useCallback((idx) => {
        setRules((r) => {
            r.splice(idx, 1);
            return [...r];
        });
    }, []);

    const handleAddRule = useCallback(() => {
        setRules((r) => [...r, NewEmptyRule()]);
    }, [setRules]);

    const updateRules = useCallback((opt) => {
        setRules(opt);
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }

        const processedRules = processRules(rules);

        debouncedLoad({
            variables: {
                where: processedRules,
                count: 15,
                task_id: task.id,
            }
        });
    }, [debouncedLoad, rules, task.id])

    const nextPage = useCallback(() => {
        setPageCursors((pc) => [...pc, nextPageCursor]);

        setAddedImmediate([]);

        getProducts({
            variables: {
                where: processRules(rules),
                cursor: nextPageCursor,
                count: 15,
                task_id: task.id,
            }
        });

    }, [getProducts, nextPageCursor, rules, task.id]);

    const previousPage = useCallback(() => {
        // Remove the last item from the page, because that is the cursor of the current page
        const cursors = pageCursors.slice(null, -1);
        const cursor = cursors.pop();

        setAddedImmediate([]);

        getProducts({
            variables: {
                where: processRules(rules),
                cursor: cursor,
                count: 15,
                task_id: task.id,
            }
        });

        setPageCursors(pageCursors.slice(null, -1));
    }, [getProducts, pageCursors, rules, task.id]);

    let rowMarkup;

    if (products) {
        rowMarkup = products.map((product, idx) => (
            <IndexTable.Row id={product.id} key={product.id} position={idx}>
                {/* Image */}
                <IndexTable.Cell>
                    <div className="image-container">
                        <Thumbnail
                            source={product.image_url ? product.image_url : "https://cdn.shopify.com/s/images/admin/no-image-large.gif?da5ac9ca38617f8fcfb1ee46268f66d451ca66b4"}
                            alt={`Product image for ${product.name}`}/>
                    </div>
                </IndexTable.Cell>

                {/* Product Name */}
                <IndexTable.Cell>
                    {product.name}
                </IndexTable.Cell>

                {/* Price */}
                <IndexTable.Cell>
                    ${(product.price / 100).toFixed(2)}
                </IndexTable.Cell>

                {/* SKU */}
                <IndexTable.Cell>
                    {product.variants.map(v => (v.sku ? <Tag>{v.sku}</Tag> : null))}
                </IndexTable.Cell>

                {/* Barcode */}
                <IndexTable.Cell>
                    {product.variants.map(v => (v.barcode ? <Tag>{v.barcode}</Tag> : null))}
                </IndexTable.Cell>

                {/* Quantity */}
                <IndexTable.Cell>
                    {product.inventory_amount}
                </IndexTable.Cell>

                {/* Number of Variants */}
                <IndexTable.Cell>
                    {product.variants.length}
                </IndexTable.Cell>

                {/* Add To List */}
                <IndexTable.Cell>
                    {
                        (product.already_added || addedImmediate.includes(product.id)) ?
                            <Button disabled>Already Added</Button> :
                            <Button onClick={() => {
                                setAddedImmediate([...addedImmediate, product.id]);
                                onAddToList(product);
                            }}>Add To List</Button>
                    }
                </IndexTable.Cell>
            </IndexTable.Row>
        ))
    }

    return <Card title="Find Products" actions={[{
        content: "Add Rule", onAction: () => {
            handleAddRule()
        },
    }]}>
        {
            collectionCount > 1 ?
                <Card.Section>
                    <Banner status="warning" title="Search Warning">
                        <p>Searching by multiple collections is not supported. Only the last collection rule will be
                            taken in to consideration. Please remove all but one collection rule</p>
                    </Banner>
                </Card.Section>
                : null
        }
        {
            error ? <Card.Section>
                <Banner status="critical" title="Something Went Wrong">
                    <p>Something went wrong while searching for products</p>
                </Banner>
            </Card.Section> : null
        }
        <Card.Section>
            <div className="flex-container">
                {
                    rules.map((r, idx) => <div className="mb" key={idx}><SearchRule
                        singleCollectionOnlySearch={singleCollectionOnlySearch(rules)}
                        onRemove={() => handleRemoveRule(idx)} rule={r} idx={idx}
                        setRules={updateRules}/></div>)
                }
            </div>
        </Card.Section>
        <Card.Section>
            <IndexTable resourceName={{singular: "product", plural: "products"}}
                        loading={loading}
                        hasMoreItems
                        selectable={false}
                        headings={[
                            {title: ""},
                            {title: "Name"},
                            {title: "Price"},
                            {title: "SKU"},
                            {title: "Barcode"},
                            {title: "Quantity"},
                            {title: "Variants"},
                        ]} itemCount={products ? products.length : 0}>
                {rowMarkup}
            </IndexTable>
            <Pagination hasPrevious={hasPreviousPage} hasNext={hasNextPage} onPrevious={previousPage} onNext={nextPage}
                        label={
                            <span>{pageNumber}</span>
                        }/>
        </Card.Section>
    </Card>;
}

export default ProductSearch;