import React, {useCallback, useReducer, useState} from "react";
import {Card, EmptyState, Heading, Icon, IndexTable, Stack, Toast, useIndexResourceState,} from "@shopify/polaris";
import PriceAdjustmentRow from "./PriceAdjustmentRow";
import {CircleTickMajor, UploadMajor} from "@shopify/polaris-icons";
import {gql, useMutation} from "@apollo/client";

const DELETE_PRODUCT = gql`
    mutation DeleteProduct($task_id: String!, $product_ids: [String!]!){
        delete_products_from_task(task_id: $task_id, product_ids: $product_ids)
    }`;

const ERROR_TYPES = {
    SAVE_PRICE_ERROR: "save_price_error",
    DELETE_PRODUCT_ERROR: "delete_product_error",
};

const ERROR_MSGS = {
    SAVE_PRICE_ERROR: "Failed to save price adjustment price.",
    DELETE_PRODUCT_ERROR: "Failed to delete product with price adjustment.",
};

const hasErrorReducer = (state, action) => {
    switch (action.type) {
        case ERROR_TYPES.SAVE_PRICE_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.SAVE_PRICE_ERROR,
            }
        }
        case ERROR_TYPES.DELETE_PRODUCT_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.DELETE_PRODUCT_ERROR,
            }
        }
        case "CLOSE": {
            return {
                hasError: false,
                content: "",
            }
        }
        default: {
            throw new Error("unknown hasError reducer action");
        }
    }
};

function PriceAdjustments({task, paginationComponent, loading, disabled, searchComponent, handleLoadProducts}) {
    const [hasErrorState, dispatchHasError] = useReducer(hasErrorReducer,
        {hasError: false, content: ""},
        () => ({hasError: false, content: ""}));

    const [savePrice] = useMutation(gql`
                mutation SavePrice($task_id: String!, $product_id: String!, $new_price: PriceSet!) {
                    set_product_price(task_id: $task_id, product_id: $product_id, price: $new_price) {
                        id
                        altered_price {
                            compare_at_price_cents
                            price_cents
                        }
                    }
                }`,
        {
            onError: (e) => {
                console.error(e);
                dispatchHasError({type: ERROR_TYPES.SAVE_PRICE_ERROR});
            }
        }
    );

    const [deleteProduct] = useMutation(DELETE_PRODUCT, {
        onCompleted: () => {
            handleLoadProducts();
        },
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: ERROR_TYPES.DELETE_PRODUCT_ERROR});
            handleLoadProducts();
        }
    });

    const [operationOngoing, setOperationOngoing] = useState(false);

    let products = task.products;

    if (!products) {
        products = [];
    }

    const variants = products;

    // for (const product of products) {
    //     const dVariants = product.variants.map(v => ({
    //         ...v,
    //         confirmed: product.confirmed,
    //     }))
    //     variants.push(...dVariants);
    // }

    const headings = [
        {title: ""}, // Image column has no header
        {title: "Name"},
        {title: "SKU"},
        {title: "Barcode"},
        {title: "Current Price"},
        {title: "New Price"},
        {title: "Quantity"},
        {title: "Compare At Price"},
        {title: "Price Adjustment"},
    ];

    const doSaveAlteredPrice = useCallback(async (variant) => {
        setOperationOngoing(true);

        await savePrice({
            variables: {
                task_id: task.id,
                product_id: variant.id,
                new_price: {
                    price_cents: variant.altered_price.price_cents,
                    compare_at_price_cents: variant.altered_price.compare_at_price_cents,
                }
            }
        });

        setOperationOngoing(false);
    }, [savePrice, task.id])

    const {selectedResources, allResourcesSelected, handleSelectionChange} = useIndexResourceState(variants);

    const rowMarkup = variants.map((product, idx) => (
        <PriceAdjustmentRow key={product.id} doSaveAlteredPrice={doSaveAlteredPrice} variant={product} task={task}
                            idx={idx} disabled={disabled} selected={selectedResources.includes(product.id)}/>
    ));

    const emptyState = <EmptyState
        heading="Nothing here yet"
        image="data:image/svg+xml,%3csvg width='60' height='60' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M41.87 24a17.87 17.87 0 11-35.74 0 17.87 17.87 0 0135.74 0zm-3.15 18.96a24 24 0 114.24-4.24L59.04 54.8a3 3 0 11-4.24 4.24L38.72 42.96z' fill='%238C9196'/%3e%3c/svg%3e"
    >
        {false ?
            <p>Add products using the "Find Products" window above, or by importing a file</p> :
            <p>Add products by importing a file using the "Import From File" button above</p>}


    </EmptyState>;

    const saveStatus = operationOngoing ?
        <Stack>
            <Stack.Item>
                Saving
            </Stack.Item>
            <Stack.Item>
                <Icon source={UploadMajor}/>
            </Stack.Item>
        </Stack> :
        <Stack>
            <Stack.Item>

            </Stack.Item>
            <Stack.Item>
                <Icon source={CircleTickMajor} color="success"/>
            </Stack.Item>
        </Stack>

    const toastMarkup = hasErrorState.hasError ? (
        <Toast content={hasErrorState.content} error onDismiss={() => dispatchHasError({type: "CLOSE"})}
               duration={4500}/>
    ) : null;

    const promotedBulkActions = [{
        content: selectedResources.length === 1 ? "Delete Product" : "Delete Products",
        onAction: async () => {
            try {
                await deleteProduct({
                    variables: {
                        task_id: task.id,
                        product_ids: selectedResources,
                    }
                });

                handleSelectionChange('all', false);
            } catch (e) {
                console.error(e);
            }
        },
    }];

    return <Card>
        <Card.Section>
            <Stack>
                <Stack.Item fill>
                    <Heading>Price Adjustments Summary</Heading>
                </Stack.Item>
                <Stack.Item>
                    {saveStatus}
                </Stack.Item>
            </Stack>
        </Card.Section>

        <Card.Section>
            <div style={{display: "flex"}}>
                <div style={{flex: 1}}>{searchComponent}</div>
            </div>
            <IndexTable
                resourceName={{singular: "price adjustment", plural: "price adjustments"}}
                selectable={false}
                headings={headings}
                itemCount={variants.length}
                emptyState={emptyState}
                loading={loading}
                selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                promotedBulkActions={promotedBulkActions}
            >
                {rowMarkup}
            </IndexTable>
            {paginationComponent}
            {toastMarkup}
        </Card.Section>
    </Card>
}

export default PriceAdjustments;
