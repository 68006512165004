import React, {useCallback} from "react";
import {gql, useSubscription} from "@apollo/client";
import {Caption, ProgressBar, Spinner, Stack} from "@shopify/polaris";

const IMPORT_STATUS_SUBSCRIPTION = gql`
    subscription ImportStatus($workflow_id: String!, $run_id: String!) {
        file_import_progress(workflow_id: $workflow_id, run_id: $run_id) {
            status
            progress_percent
            failed
            products_matched
            products_searched
        }
    }`;

function FileImportStatus({workflow_id, run_id, onImportFinished}) {
    const onFinished = useCallback(() => {
        onImportFinished();
    }, [onImportFinished]);

    const {data, loading} = useSubscription(IMPORT_STATUS_SUBSCRIPTION, {
        variables: {
            workflow_id,
            run_id
        },

        fetchPolicy: 'network-only',

        onSubscriptionData({subscriptionData: {data}}) {
            if (!data) {
                return
            }

            const file_import_progress = data.file_import_progress;

            if (file_import_progress && file_import_progress.status === "finished") {
                if (onImportFinished) {
                    onImportFinished(file_import_progress.products_matched);
                }
            }
        }
    });

    let body;

    if (loading) {
        body = <Stack alignment="center">
            <Stack.Item>
                <Spinner size="small"/>
            </Stack.Item>
        </Stack>;
    } else {
        if (data && data.file_import_progress) {
            const {progress_percent, status, failed, products_searched, products_matched} = data.file_import_progress;

            const finished = status === "finished";

            body = <div>
                <Caption>{status}</Caption>
                <Caption>Matched {products_matched}/{products_searched}</Caption>
                <ProgressBar size="small" color={failed ? 'critical' : finished ? 'success' : 'primary'}
                             progress={progress_percent}/>
            </div>
        } else {
            body = <div/>
        }
    }

    return body;
}

export default FileImportStatus;