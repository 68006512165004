import './App.css';
import {BrowserRouter} from 'react-router-dom';
import {AppProvider} from '@shopify/polaris';
import Router from "./Router";
import Routes from "./Routes";

import enTranslations from '@shopify/polaris/locales/en.json';

function App() {
    return (
        <AppProvider i18n={enTranslations}>
            <BrowserRouter>
                <Router/>
                { /* Routes are propagated to shopify to prevent full page reload */}
                <Routes/>
            </BrowserRouter>
        </AppProvider>
    );
}

export default App;
