import React, {useCallback, useEffect, useReducer, useState} from "react";
import {
    Banner,
    Card,
    ChoiceList,
    ContextualSaveBar,
    FormLayout,
    Frame,
    Icon,
    Layout,
    Modal,
    Page,
    Select,
    Spinner,
    TextField,
    Toast
} from "@shopify/polaris";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {useHistory, useParams} from "react-router";
import ProductSearch from "../components/ProductSearch";
import PriceAdjustments from "../components/PriceAdjustments";
import FileImportStatus from "../components/FileImportStatus";
import PriceChangeStatus from "../components/PriceChangeStatus";
import PriceChangeUndoStatus from "../components/PriceChangeUndoStatus";
import ListPagination from "../components/ListPagination";
import validations from "../validations";
import {useDebounce} from "use-debounce";
import {SearchMajor} from "@shopify/polaris-icons";
import {TitleBar} from "@shopify/app-bridge-react";
import ReviewProductsModal from "../components/ReviewProductsModal";

// TODO tech-debt
// TODO There is occasionally a problem where products are loaded more times than they need to be
// TODO There is also a problem where occasionally when loading products, a search is performed, when no search was requested

const GET_TASK = gql`
    query GetTasks($id: String!) {
        task_by_id(id: $id) {
            id
            name
            price_mode
            percentage_change
            cents_change

            compare_at_price_mode
            change_type

            change_start
            change_end

            price_change_workflow_id
            price_change_workflow_run_id
            price_change_undo_workflow_id
            price_change_undo_workflow_run_id

            add_tags

            has_unconfirmed_products
            has_imported_products
            status

            products {
                products {
                    id
                    shopify_id
                    shopify_title
                    confirmed
                    parent_product_id
                    shopify_image_url
                    inventory_amount
                    shopify_barcode
                    shopify_sku

                    original_price {
                        price_cents
                        price_context
                        compare_at_price_cents
                        currency_iso_code
                    }

                    altered_price {
                        price_cents
                        price_context
                        compare_at_price_cents
                        currency_iso_code
                    }
                }

                total_count
            }
        }
    }`;

const SAVE_TASK = gql`
    mutation UpdateTask($id: String!, $update: TaskUpdate!) {
        update_task(task_id: $id, update: $update) {
            id
        }
    }`;

const ADD_PRODUCT = gql`
    mutation AddProducts($task_id: String!, $products: [AddTaskProduct!]!) {
        add_task_product(task_id:$task_id, products: $products) {
            id

            products {
                products {
                    shopify_id
                    shopify_title
                }
            }
        }
    }`;

const IMPORT_PRODUCTS = gql`
    mutation ImportFile($file: Upload!, $task_id: String!) {
        import_products_from_file(file: $file, task_id: $task_id) {
            workflow_id
            run_id
        }
    }`;

const RUN_TASK_NOW = gql`
    mutation RunTask($task_id: String!) {
        run_task_now(task_id: $task_id) {
            workflow_id
            run_id
        }
    }`;

const CONFIRM_ALL_PRODUCTS = gql`
    mutation ConfirmAllProducts($task_id: String!) {
        confirm_all_unconfirmed(task_id: $task_id)
    }`;

const DO_ROLLBACK = gql`
    mutation DoRollback($task_id: String!) {
        rollback_task(task_id: $task_id) {
            workflow_id
            run_id
        }
    }`;

const LIST_TASK_PRODUCTS = gql`
    query ListTaskProducts($id: String!, $limit: Int, $offset: Int, $query: ProductSearch) {
        task_by_id(id: $id) {
            id
            products (limit: $limit, offset: $offset, query: $query) {
                products {
                    id
                    shopify_id
                    shopify_title
                    confirmed
                    parent_product_id
                    shopify_image_url
                    inventory_amount
                    shopify_barcode
                    shopify_sku

                    original_price {
                        price_cents
                        price_context
                        compare_at_price_cents
                        currency_iso_code
                    }

                    altered_price {
                        price_cents
                        price_context
                        compare_at_price_cents
                        currency_iso_code
                    }
                }

                total_count
            }
        }
    }`;

const PRICE_ADJUSTMNET_PAGE_LIMIT = 30;

const VALIDATION_ERROR_MSGS = {
    NAME_IS_REQUIRED: "Task name is required",
    NAME_INVALID_LENGTH: "Task name should be 2 - 45 characters",
    PRODUCT_TAG_INVALID_LENGTH: "Product tag should be 2 - 45 characters",
    BULK_PERCENT_REQUIRED: "Percentage change is required",
    BULK_DECREASE_OVER_100: "A decrease above 100% is not allowed",
    BULK_PERCENT_NEGATIVE: "Percentage change should be greater than (or equal to) 0",
    BULK_DOLLAR_REQUIRED: "Dollar value is required",
    BULK_DOLLAR_INVALID_MONEY: "Dollar value should be larger than $0.00",
    BULK_DOLLAR_IS_SMALL_MONEY: "Did you accidentally miss typed the dollar value?",
    START_TIME_REQUIRED: "Start time is required",
    END_TIME_REQUIRED: "End time is required",
    FILE_IMPORT_REQUIRED: "Please import a file before setting the start time",
    PRODUCTS_LESS_THAN_0: "Cannot set start time for a task with 0 affected products",
    END_TIME_AFTER_START_TIME: "The end time must be after the start time"
};

function validateDatetimeValue(date) {
    if (isNaN(Date.parse(date))) {
        return false
    } else {
        return true
    }
}

function formatForDatetimeLocal(date) {
    if (date === null || date === undefined) {
        date = new Date();
    } else if (typeof date === "string") {
        date = new Date(date);
    }

    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().slice(0, 16);
}

const focusedElementReducer = (state, action) => {
    switch (action.type) {
        case "focus":
            return true;
        case "blur":
            return false;
        default:
            throw new Error("unknown action");
    }
};

const QUERY_ERROR_TYPES = {
    LOAD_TASK_ERROR: "load_task_error",
    LOAD_PRICE_ADJUSTMENTS_ERROR: "load_price_adjustments_error",
    LOAD_NEXT_PRICE_ADJUSTMENTS_ERROR: "load_next_price_adjustments_error",
};

const MUTATION_ERROR_TYPES = {
    SAVE_TASK_ERROR: "save_task_error",
    ADD_PRODUCT_ERROR: "add_product_error",
    IMPORT_PRODUCTS_ERROR: "import_products_error",
    RUN_TASK_NOW_ERROR: "run_task_now_error",
    CONFIRM_ALL_PRODUCTS_ERROR: "confirm_all_products_error",
    DO_ROLLBACK_ERROR: "do_roll_back_error",
};

const ERROR_MSGS = {
    LOAD_TASK_ERROR: "Failed to load task details.",
    LOAD_PRICE_ADJUSTMENTS_ERROR: "Failed to load price adjustments of this task.",
    SAVE_TASK_ERROR: "Failed to save changes to task.",
    ADD_PRODUCT_ERROR: "Failed to add product for price adjustment.",
    IMPORT_PRODUCTS_ERROR: "Failed to import price adjustment products from file.",
    RUN_TASK_NOW_ERROR: "Failed to run the task.",
    CONFIRM_ALL_PRODUCTS_ERROR: "Failed to confirm products",
    DO_ROLLBACK_ERROR: "Failed to do rollback",
};

const hasErrorReducer = (state, action) => {
    switch (action.type) {
        case QUERY_ERROR_TYPES.LOAD_TASK_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.LOAD_TASK_ERROR,
            }
        }
        case QUERY_ERROR_TYPES.LOAD_PRICE_ADJUSTMENTS_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.LOAD_PRICE_ADJUSTMENTS_ERROR,
            }
        }
        case QUERY_ERROR_TYPES.LOAD_NEXT_PRICE_ADJUSTMENTS_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.LOAD_PRICE_ADJUSTMENTS_ERROR,
            }
        }
        case MUTATION_ERROR_TYPES.SAVE_TASK_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.SAVE_TASK_ERROR,
            }
        }
        case MUTATION_ERROR_TYPES.ADD_PRODUCT_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.ADD_PRODUCT_ERROR,
            }
        }
        case MUTATION_ERROR_TYPES.IMPORT_PRODUCTS_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.IMPORT_PRODUCTS_ERROR,
            }
        }
        case MUTATION_ERROR_TYPES.RUN_TASK_NOW_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.RUN_TASK_NOW_ERROR,
            }
        }
        case MUTATION_ERROR_TYPES.CONFIRM_ALL_PRODUCTS_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.CONFIRM_ALL_PRODUCTS_ERROR,
            }
        }
        case MUTATION_ERROR_TYPES.DO_ROLLBACK_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.DO_ROLLBACK_ERROR,
            }
        }
        case "CLOSE": {
            return {
                hasError: false,
                content: "",
            }
        }
        default: {
            throw new Error("unknown hasError reducer action");
        }
    }
};

const calculateItemNumber = (pageNumber, offset, listLength) => {
    let itemNumber;
    if (offset === 0) {
        itemNumber = listLength;
    } else {
        itemNumber = (pageNumber - 1) * PRICE_ADJUSTMNET_PAGE_LIMIT + listLength;
    }
    return itemNumber;
}

function TaskView(callback, deps) {
    const history = useHistory();

    const {id} = useParams();

    const [firstTaskLoad, setFirstTaskLoad] = useState(true);
    const [task, setTask] = useState({});
    const [untouchedTask, setUntouchedTask] = useState({});
    const [formDirty, setFormDirty] = useState(false);
    const [updateToastActive, setUpdateToastActive] = useState(false);
    const [priceAlterationMode, setPriceAlterationMode] = useState("percentage");
    const [taskLoadCount, setTaskLoadCount] = useState(1);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [progressModalOpen, setProgressModalOpen] = useState(false);
    const [importStatusParams, setImportStatusParams] = useState({});
    const [progressParams, setProgressParams] = useState({});

    const [productsCount, setProductsCount] = useState(0);
    const [productsItemNumber, setProductsItemNumber] = useState(0);
    const [toggleReviewProductModal, setToggleReviewProductModal] = useState(false);
    const [allProductsReviewed, setAllProductsReviewed] = useState(false);

    const [loadTask, {loading, error}] = useLazyQuery(GET_TASK, {
        variables: {
            id: id,
        },

        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',

        onCompleted({task_by_id}) {
            setFirstTaskLoad(false);

            const newTask = {
                ...task_by_id,
                change_start: task_by_id.change_start ? formatForDatetimeLocal(new Date(task_by_id.change_start)) : null,
                change_end: task_by_id.change_end ? formatForDatetimeLocal(new Date(task_by_id.change_end)) : null,
                cents_change: task_by_id.cents_change ? (task_by_id.cents_change / 100).toFixed(2) : null,
                percentage_change: task_by_id.percentage_change ? (task_by_id.percentage_change.toString()) : null,
                products: task_by_id.products.products,
            };

            if (newTask.cents_change) {
                setPriceAlterationMode("dollar");
            } else {
                setPriceAlterationMode("percentage");
            }

            // If some values on the task have been changed, do not update the original task, only the untouched one
            if (!formDirty) {
                setTask(newTask);
            }

            setUntouchedTask(JSON.parse(JSON.stringify(newTask)));

            setTaskLoadCount(taskLoadCount + 1);

            setProductsItemNumber(calculateItemNumber(
                priceAdjustmentPageNumber, offset, task_by_id.products.products.length
            ));
            setProductsCount(task_by_id.products.total_count);
        },
        onError(e) {
            console.error(e);
            dispatchHasError({type: QUERY_ERROR_TYPES.LOAD_TASK_ERROR});
        },
    });

    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
    const [offset, setOffset] = useState(0);
    const [priceAdjustmentPageNumber, setPriceAdjustmentPageNumber] = useState(1);

    const [loadProducts, loadProductsOpt] = useLazyQuery(LIST_TASK_PRODUCTS, {
        variables: {
            id: id,
            limit: PRICE_ADJUSTMNET_PAGE_LIMIT,
            offset: offset,
            query: {
                title_contains: debouncedSearchQuery,
            },
        },

        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',

        onCompleted({task_by_id}) {
            const fetchedProducts = task_by_id.products.products ? task_by_id.products.products : [];

            setUntouchedTask({
                ...untouchedTask,
                products: fetchedProducts,
            });
            setProductsItemNumber(calculateItemNumber(
                priceAdjustmentPageNumber, offset, task_by_id.products.products.length
            ));
            setProductsCount(task_by_id.products.total_count);
        },
        onError(e) {
            console.error(e);
            dispatchHasError({type: QUERY_ERROR_TYPES.LOAD_NEXT_PRICE_ADJUSTMENTS_ERROR});
        },
    });

    // search query callbacks
    useEffect(async () => {
        setOffset(0);
        setPriceAdjustmentPageNumber(1);
        await loadProducts();
    }, [debouncedSearchQuery]);

    const searchPriceAdjustments = <TextField
        labelHidden label={"Search price adjustments"} autoComplete="off"
        onChange={setSearchQuery}
        value={searchQuery}
        prefix={<Icon source={SearchMajor}/>}
        placeholder={"Filter by product name"}
    />

    // validation error message states, reducers
    const [nameErrorMsg, setNameErrorMsg] = useState("");
    const [listTagErrorMsg, setListTagErrorMsg] = useState("");
    const [bulkPercentChangeErrorMsg, setBulkPercentChangeErrorMsg] = useState("");
    const [bulkDollarChangeErrorMsg, setBulkDollarChangeErrorMsg] = useState("");
    const [bulkDollarChangeHelpText, setBulkDollarChangeHelpText] = useState("");
    const [startTimeErrorMsg, setStartTimeErrorMsg] = useState("");
    const [endTimeErrorMsg, setEndTimeErrorMsg] = useState("");


    const [taskNameFocusState, dispatchTaskNameFocus] = useReducer(focusedElementReducer, false, () => false);
    const [productTagFocusState, dispatchProductTagFocus] = useReducer(focusedElementReducer, false, () => false);
    const [bulkPercentChangeFocusState, dispatchBulkPercentChangeFocus] = useReducer(focusedElementReducer, false, () => false);
    const [bulkDollarChangeFocusState, dispatchBulkDollarChangeFocus] = useReducer(focusedElementReducer, false, () => false);

    // input validations via useEffect
    useEffect(() => {
        if (taskNameFocusState) {
            if (!validations.isRequired((task.name || "").trim())) {
                setNameErrorMsg(VALIDATION_ERROR_MSGS.NAME_IS_REQUIRED);
            } else if (!validations.hasProperLength((task.name || "").trim())) {
                setNameErrorMsg(VALIDATION_ERROR_MSGS.NAME_INVALID_LENGTH);
            } else {    // valid
                setNameErrorMsg("");
            }
        }
    }, [task.name, taskNameFocusState]);

    useEffect(() => {
        if (productTagFocusState) {
            if (!validations.hasProperLength(task.add_tags ? task.add_tags[0] : "")) {
                setListTagErrorMsg(VALIDATION_ERROR_MSGS.PRODUCT_TAG_INVALID_LENGTH);
            } else {    // valid
                setListTagErrorMsg("");
            }
        }
    }, [task.add_tags, productTagFocusState]);

    useEffect(() => {
        if (bulkPercentChangeFocusState) {
            if (!validations.isRequired((task.percentage_change || "").trim())) {
                setBulkPercentChangeErrorMsg(VALIDATION_ERROR_MSGS.BULK_PERCENT_REQUIRED);
            } else if (validations.isNegative((task.percentage_change || "0.00").trim())) {
                setBulkPercentChangeErrorMsg(VALIDATION_ERROR_MSGS.BULK_PERCENT_NEGATIVE);
            } else if (task.percentage_change && task.price_mode === "decrease" && !validations.isLessThan((task.percentage_change || "0.00").trim(), 100.01)) {
                setBulkPercentChangeErrorMsg(VALIDATION_ERROR_MSGS.BULK_DECREASE_OVER_100);
            } else { // valid
                setBulkPercentChangeErrorMsg("")
            }
        }
    }, [task.percentage_change, bulkPercentChangeFocusState]);

    useEffect(() => {
        if (bulkDollarChangeFocusState) {
            if (!validations.isRequired((task.cents_change || "").trim())) {
                setBulkDollarChangeErrorMsg(VALIDATION_ERROR_MSGS.BULK_DOLLAR_REQUIRED);
                setBulkDollarChangeHelpText("")
            } else if (!validations.isMoney((task.cents_change || "0.00").trim())) {
                setBulkDollarChangeErrorMsg(VALIDATION_ERROR_MSGS.BULK_DOLLAR_INVALID_MONEY);
                setBulkDollarChangeHelpText("");
            } else if (validations.isSmallMoney((task.cents_change || "0.00").trim())) {
                setBulkDollarChangeHelpText(VALIDATION_ERROR_MSGS.BULK_DOLLAR_IS_SMALL_MONEY);
                setBulkDollarChangeErrorMsg("");
            } else {
                setBulkDollarChangeErrorMsg("");
                setBulkDollarChangeHelpText("");
            }
        }

    }, [task.cents_change, bulkDollarChangeFocusState]);

    useEffect(() => {
        if (task.change_type === "date-based") {
            if (task.change_start && !task.change_end && task.status === "ready") {
                setStartTimeErrorMsg("");
                setEndTimeErrorMsg(VALIDATION_ERROR_MSGS.END_TIME_REQUIRED)
            } else if (!task.change_start && task.change_end && task.status === "ready") {
                setEndTimeErrorMsg("");
                setStartTimeErrorMsg(VALIDATION_ERROR_MSGS.START_TIME_REQUIRED);
            } else if (task.change_end && task.change_start && task.change_end <= task.change_start) {
                setEndTimeErrorMsg(VALIDATION_ERROR_MSGS.END_TIME_AFTER_START_TIME);
                setStartTimeErrorMsg("");
            } else {    // valid
                setStartTimeErrorMsg("");
                setEndTimeErrorMsg("");
            }
        } else {
            setStartTimeErrorMsg("");
            setEndTimeErrorMsg("");
        }
    }, [task.change_type, task.change_start, task.change_end, task.status])

    // Error handling state & reducer
    const [hasErrorState, dispatchHasError] = useReducer(hasErrorReducer,
        {hasError: false, content: ""},
        () => ({hasError: false, content: ""}));


    // Run loadTask once to do the initial data load. Deps should be [], not anything else
    // This causes the effect to only ever run once
    useEffect(() => {
        loadTask();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [updateTask] = useMutation(SAVE_TASK, {
        onCompleted: () => {
            loadTask();
            setFormDirty(false);
            setUpdateToastActive(true);
        },
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: MUTATION_ERROR_TYPES.SAVE_TASK_ERROR});
            setUpdateToastActive(false);
        },
    });

    const [addTaskProduct] = useMutation(ADD_PRODUCT, {
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: MUTATION_ERROR_TYPES.ADD_PRODUCT_ERROR});
        },
    });

    const [importFromFile] = useMutation(IMPORT_PRODUCTS, {
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: MUTATION_ERROR_TYPES.IMPORT_PRODUCTS_ERROR});
        },
    });

    const [runTaskNow] = useMutation(RUN_TASK_NOW, {
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: MUTATION_ERROR_TYPES.RUN_TASK_NOW_ERROR});
        },
    });

    const [confirmAllProducts] = useMutation(CONFIRM_ALL_PRODUCTS, {
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: MUTATION_ERROR_TYPES.CONFIRM_ALL_PRODUCTS_ERROR});
        },
    });

    const [doRollback] = useMutation(DO_ROLLBACK, {
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: MUTATION_ERROR_TYPES.DO_ROLLBACK_ERROR});
        },
    });

    async function saveTask() {
        // if (!task.change_start) {
        //     alert("Please set the time(s) at which this task should run");
        //     return
        // }

        try {
            await updateTask({
                variables: {
                    id: task.id,
                    update: {
                        name: task.name,
                        price_mode: task.price_mode,
                        percentage_change: task.percentage_change,
                        cents_change: task.cents_change ? Math.round(task.cents_change * 100) : null,
                        compare_at_price_mode: task.compare_at_price_mode,
                        change_type: task.change_type,
                        change_start: task.change_start ? (new Date(task.change_start)).toISOString() : null,
                        change_end: task.change_end ? (new Date(task.change_end)).toISOString() : null,
                        add_tags: task.add_tags,
                    }
                },
            });

            // await loadTask();
            //
            // setFormDirty(false);
            // setUpdateToastActive(true);
        } catch (e) {
            throw e;
        }
    }

    const handleNameChange = useCallback((value) => {
        setFormDirty(true);

        setTask((t) => ({
            ...t,
            name: value,
        }));
    }, []);

    /*    const handlePercentageChangeChange = useCallback((value) => {
            setBulkDollarChangeErrorMsg("");
            setTask((t) => ({
                ...t,
                percentage_change: value,
                cents_change: null,
            }));

            setFormDirty(true);
        }, []);

        const handleDollarChangeChange = useCallback((value) => {
            setBulkPercentChangeErrorMsg("");
            setTask((t) => ({
                ...t,
                cents_change: value,
                percentage_change: null,
            }));

            setFormDirty(true);
        }, []);

        const handlePriceAlterationModeChange = useCallback((value) => {
            setPriceAlterationMode(value[0]);

            //setFormDirty(true);
        }, []);*/

    const handleRunTaskNow = useCallback(async (taskId) => {
        const {data} = await runTaskNow({
            variables: {
                task_id: taskId
            }
        });

        setProgressParams({
            workflow_id: data.run_task_now.workflow_id,
            run_id: data.run_task_now.run_id,
            mode: "change"
        });

        loadTask();
        setProgressModalOpen(true);
    }, [loadTask, runTaskNow]);

    const handleConfirmAllProducts = async (taskId) => {
        await confirmAllProducts({
            variables: {
                task_id: taskId
            }
        });

        await loadTask();
    }

    const handlePriceTypeChange = useCallback((value) => {
        setFormDirty(true);

        setTask((t) => ({
            ...t,
            price_mode: value,
        }));
    }, []);

    const handleAddProductToTask = useCallback(
        async (product) => {
            try {
                await addTaskProduct({
                    variables: {
                        task_id: untouchedTask.id,
                        products: [{shopify_id: product.id, title: product.name}]
                    }
                });

                await loadTask();
            } catch (e) {
                console.log(e);
            }
        },
        [addTaskProduct, loadTask, untouchedTask.id]
    );

    const handleCompareAtPriceTypeChange = useCallback((value) => {
        setFormDirty(true);

        setTask((t) => ({
            ...t,
            compare_at_price_mode: value,
        }));
    }, []);

    const handleAddTagTypeChange = useCallback((value) => {
        setFormDirty(true);

        setTask((t) => ({
            ...t,
            add_tags: value ? [value] : null,
        }));
    }, []);

    const handleChangeTypeChange = useCallback((value) => {
        setFormDirty(true);

        setTask((t) => ({
            ...t,
            change_type: value[0],
        }));
    }, []);

    const handleStartTimeChange = (value) => {
        if (!untouchedTask.has_imported_products) {
            setStartTimeErrorMsg(VALIDATION_ERROR_MSGS.FILE_IMPORT_REQUIRED);
            return
        }

        if (!untouchedTask.has_imported_products && task.products.length < 1) {
            setStartTimeErrorMsg(VALIDATION_ERROR_MSGS.PRODUCTS_LESS_THAN_0);
            return
        }

        setFormDirty(true);

        if (validateDatetimeValue(value)) {
            setTask((t) => ({
                ...t,
                change_start: value,
            }));
        }
    };

    const handleEndTimeChange = (value) => {
        setFormDirty(true);

        if (validateDatetimeValue(value)) {
            setTask((t) => ({
                ...t,
                change_end: value,
            }));
        }
    };

    const handleFileUploadClicked = useCallback(() => {
        document.getElementById("file-upload-input").click();
    }, []);

    const handleUploadFileSelected = useCallback(async (val) => {
        const file = val.target.files[0];

        try {
            const {data} = await importFromFile({
                variables: {
                    file,
                    task_id: untouchedTask.id,
                }
            });

            setImportStatusParams({
                workflow_id: data.import_products_from_file.workflow_id,
                run_id: data.import_products_from_file.run_id,
            });

            setImportModalOpen(true);
        } catch (err) {
            console.log("IMPORT ERROR", err);
        }

        val.target.value = null;
    }, [importFromFile, untouchedTask]);

    const handleImportFinished = (products_matched) => {
        setImportModalOpen(false);

        if (products_matched < 1) {
            alert("Price adjustment file import completed. 0 products matched.");
        }
        loadTask();
    };

    const handleDoRollback = useCallback(async (taskId) => {
        const {data} = await doRollback({
            variables: {
                task_id: taskId,
            }
        });

        setProgressParams({
            workflow_id: data.rollback_task.workflow_id,
            run_id: data.rollback_task.run_id,
            mode: "undo",
        });

        loadTask();

        setProgressModalOpen(true);
    }, [doRollback, loadTask])

    const handleReviewProductModalClose = () => {
        setToggleReviewProductModal(false);
    };

    let changeBar;

    if (formDirty) {
        const disableSave = loading || nameErrorMsg
            || bulkPercentChangeErrorMsg || bulkDollarChangeErrorMsg
            || startTimeErrorMsg || endTimeErrorMsg;
        changeBar = <ContextualSaveBar message="Unsaved changes" saveAction={{
            onAction: () => saveTask(),
            onError: () => dispatchHasError({type: MUTATION_ERROR_TYPES.SAVE_TASK_ERROR}),
            loading: loading,
            disabled: disableSave,
        }} discardAction={{
            onAction: () => {
                setTask(untouchedTask);
                setFormDirty(false);
            }
        }}/>
    }

    const updateToastToggle = useCallback(() => setUpdateToastActive((active) => !active), []);

    let updateToast = updateToastActive && !hasErrorState.hasError ?
        <Toast content="Task updated!" onDismiss={updateToastToggle} duration={4500}/> : null;

    const handlePriceAdjustmentNext = useCallback(() => {
        setOffset(offset + PRICE_ADJUSTMNET_PAGE_LIMIT);
        loadProducts();
        setPriceAdjustmentPageNumber(priceAdjustmentPageNumber + 1);
    }, [loadProducts, offset, priceAdjustmentPageNumber]);

    const handlePriceAdjustmentPrev = useCallback(() => {
        if (offset >= PRICE_ADJUSTMNET_PAGE_LIMIT) {
            setOffset(offset - PRICE_ADJUSTMNET_PAGE_LIMIT);
        } else {
            setOffset(0);
        }
        loadProducts();
        setPriceAdjustmentPageNumber(priceAdjustmentPageNumber - 1);
    }, [loadProducts, offset, priceAdjustmentPageNumber]);

    const handleReloadTaskAfterApproveAllReview = async () => {
        setAllProductsReviewed(true);
        await loadTask();
    }

    const handleReloadTask = useCallback(() => {
        loadTask();
    }, [loadTask]);

    let body;
    let title = "Task";

    if ((loading && firstTaskLoad) || ((!task || !untouchedTask) && !error)) {
        body = <Spinner/>
    } else if (error) {
        body = <div>{JSON.stringify(error)}</div>
    } else {
        title = task.name;
        const canEdit = untouchedTask.status === "ready";


        const instructions = <Banner
            title="Instructions"
            noTitle
            status="info"
        >
            <p>Please set the 'Compare At Price Source' option below before importing price change data. <br/><br/>
                1. To import price change data please click the 'Import From File' button at the top of the
                page. <br/>
                2. Click the 'Review Imported Price Adjustments Button'.<br/>
                3. Review the changes, then click 'Approve All'.<br/>
                4. The task starts at the time specified in the 'Start Time' field below. <br/>
            </p>
        </Banner>


        const nameForm = <Card title="Task Name">
            <Card.Section>
                <FormLayout>
                    <TextField label="Name" labelHidden value={task.name} onChange={handleNameChange}
                               autoComplete="off" disabled={!canEdit}
                               onFocus={() => dispatchTaskNameFocus({type: "focus"})}
                               onBlur={() => dispatchTaskNameFocus({type: "blur"})}
                               error={nameErrorMsg}/>
                </FormLayout>
            </Card.Section>
        </Card>;

        let caPriceOptions = [
            {
                "label": "Use the current price as the compare at price",
                "value": "set-current"
            },
            {
                "label": "Remove the compare at price from the product",
                "value": "clear"
            },
            {
                "label": "Set a new compare at price for each product as listed in the imported sheet",
                "value": "set-manually"
            }
        ];

        const compareAtPriceTypeForm = <Card title={"Compare At Price"}>
            <Card.Section>
                <FormLayout>
                    <Select label="Compare At Price" labelHidden value={task.compare_at_price_mode}
                            disabled={!canEdit || untouchedTask.has_imported_products || untouchedTask.has_unconfirmed_products}
                            onChange={handleCompareAtPriceTypeChange} options={caPriceOptions}/>
                </FormLayout>
            </Card.Section>
        </Card>;

        const changeTypeForm = <FormLayout>
            <ChoiceList
                title="Price Adjustment Duration"
                noTitle
                selected={[task.change_type]}
                disabled={!canEdit}
                onChange={handleChangeTypeChange}
                choices={[
                    {label: 'Permanent Change', value: 'permanent'},
                    {label: 'Date Specific Change', value: 'date-based'},
                ]}/>
        </FormLayout>;

        const changeTypeTimeSelection = <div style={{"maxWidth": "250px"}}>
            <TextField label="Start Time" autoComplete="off" type="datetime-local"
                       min={formatForDatetimeLocal(new Date())}
                       disabled={!canEdit}
                       value={task.change_start}
                       onChange={handleStartTimeChange}
                       error={startTimeErrorMsg}
            />
            {
                task.change_type === 'date-based' ?
                    (<TextField label="End Time" autoComplete="off" type="datetime-local"
                                disabled={untouchedTask.status !== "active" && !canEdit}
                                min={formatForDatetimeLocal(task.change_start)} value={task.change_end}
                                onChange={handleEndTimeChange}
                                error={endTimeErrorMsg}
                    />) :
                    null
            }
        </div>;

        const changeTypeSection = <Card title="Adjustment Start Time and Duration">
            <Card.Section>
                <Layout>
                    <Layout.Section>
                        {changeTypeForm}
                    </Layout.Section>
                    <Layout.Section>
                        {changeTypeTimeSelection}
                    </Layout.Section>
                </Layout>
            </Card.Section>
        </Card>;

        const addTagTypeForm = <Card title="Add Product Tag To All Affected Products">
            <Card.Section>
                <FormLayout>
                    <TextField label="Enter Tag Here" value={task.add_tags ? task.add_tags[0] : null}
                               disabled={!canEdit}
                               onChange={handleAddTagTypeChange} autoComplete="off"
                               error={listTagErrorMsg}
                               onFocus={() => dispatchProductTagFocus({type: "focus"})}
                               onBlur={() => dispatchProductTagFocus({type: "blur"})}
                    />
                </FormLayout>
            </Card.Section>
        </Card>;

        let editStatusBanner;

        switch (untouchedTask.status) {
            case "completed":
                editStatusBanner = <Banner title="Task Complete!" status="success">
                    <p>The task has completed successfully and can no longer be edited</p>
                </Banner>
                break
            case "failed":
                editStatusBanner = <Banner title="Task Failed!" status="critical">
                    <p>We're very sorry, but this task has failed. Some prices could be incorrect. The task can no
                        longer be edited</p>
                </Banner>
                break
            case "active":
                editStatusBanner = <Banner title="Price changes active" status="info">
                    <p>The price changes for this task are currently active.
                        The task can be rolled back (which will set the prices back to their original values).
                        If this task is not permanent, the end time can also be changed
                    </p>
                </Banner>
                break
            case "completing":
                editStatusBanner = <Banner title="Wrapping up price changes" status="info">
                    <p>
                        The products in this task are currently being reset to their original values.
                        This process starts approximately 5 minutes before your scheduled "End Time".
                        During this process, the end time cannot be changed.
                    </p>
                </Banner>
                break
            case "pending":
                break
            default:
                if (!canEdit) {
                    editStatusBanner = <Banner title="Editing Disabled" status="warning">
                        <p>Editing this task is disabled due to it's status: {untouchedTask.status}</p>
                    </Banner>
                }
        }

        if (editStatusBanner) {
            editStatusBanner = <div className="mb">
                {editStatusBanner}
            </div>
        }

        let fileImportStatus = null;

        if (importStatusParams.run_id && importStatusParams.workflow_id) {
            fileImportStatus =
                <FileImportStatus run_id={importStatusParams.run_id} workflow_id={importStatusParams.workflow_id}
                                  onImportFinished={(products_matched) => handleImportFinished(products_matched)}/>
        }

        const importModal = <Modal open={importModalOpen} onClose={() => setImportModalOpen(false)} title="Import">
            <Modal.Section>
                {fileImportStatus}
            </Modal.Section>
        </Modal>;

        let progressStatus = null;

        if (progressParams.run_id && progressParams.workflow_id) {
            if (progressParams.mode === "change") {
                progressStatus =
                    <PriceChangeStatus run_id={progressParams.run_id} workflow_id={progressParams.workflow_id}
                                       onJobDone={() => loadTask()}/>
            } else if (progressParams.mode === "undo") {
                progressStatus =
                    <PriceChangeUndoStatus run_id={progressParams.run_id} workflow_id={progressParams.workflow_id}
                                           onJobDone={() => loadTask()}/>
            } else {
                progressStatus = <div>UNKNOWN MODE: "{progressParams.mode}"</div>
            }

        }

        const progressModal = <Modal open={progressModalOpen} onClose={() => setProgressModalOpen(false)}
                                     title="Progress">
            <Modal.Section>
                {progressStatus}
            </Modal.Section>
        </Modal>

        let priceChangeProgressBanner;

        if (untouchedTask.status === "pending") {
            priceChangeProgressBanner = <div className="mb">
                <Banner title="Price change in progress" status="info">
                    <PriceChangeStatus run_id={untouchedTask.price_change_workflow_run_id}
                                       workflow_id={untouchedTask.price_change_workflow_id}
                                       onJobDone={() => loadTask()}/>
                </Banner>
            </div>;
        } else if (untouchedTask.status === "completing" || untouchedTask.status === "rolling-back") {
            priceChangeProgressBanner = <div className="mb">
                <Banner title="Price change undo in progress" status="info">
                    <PriceChangeUndoStatus run_id={untouchedTask.price_change_undo_workflow_run_id}
                                           workflow_id={untouchedTask.price_change_undo_workflow_id}
                                           onJobDone={() => loadTask()}/>
                </Banner>
            </div>
        }

        let unconfirmedProductsNotice;
        if (task.has_unconfirmed_products && !allProductsReviewed) {
            unconfirmedProductsNotice = <div>
                <Banner
                    title={"Import Complete"}
                    status="warning"
                    action={{
                        content: "Review Imported Price Adjustments",
                        onAction: () => setToggleReviewProductModal(true),
                    }}
                >
                    <p>{`Your imported products are ready for review. Please check to make sure all imported products are correct`}< /p>
                </Banner>
                <div className="spacer"/>
                <ReviewProductsModal
                    task_id={untouchedTask.id}
                    active={toggleReviewProductModal}
                    handleClose={handleReviewProductModalClose}
                    handleConfirmAllProducts={handleConfirmAllProducts}
                    handleReloadTask={handleReloadTask}
                    handleReloadTaskApproveAll={handleReloadTaskAfterApproveAllReview}
                />
            </div>
        }

        // const showChangeStartBanner = task.change_type === "permanent"
        // ? !task.change_start
        // : !task.change_start || !task.change_end;

        // let changeStartBanner = showChangeStartBanner
        // ? <Banner
        //         title="Task Start Time Not Set"
        //         status="critical"
        //     >
        //         <p>Task's {task.change_type === "permanent" ? "start time" : "start and end time"} must be set in order
        //             to run this task.</p>
        //     </Banner>
        //     : null;

        const hasPrevPage = offset > 0;
        const hasNextPage = productsItemNumber < productsCount;
        const priceAdjustmentPagination = <ListPagination
            hasNext={hasNextPage}
            hasPrevious={hasPrevPage}
            handleNext={handlePriceAdjustmentNext}
            handlePrevious={handlePriceAdjustmentPrev}
            itemNumber={productsItemNumber}
            count={productsCount}
            pageNumber={priceAdjustmentPageNumber}
        />

        body = <FormLayout>
            {importModal}
            {progressModal}

            {priceChangeProgressBanner}

            {/*{changeStartBanner}*/}

            {/* Unconfirmed products don't matter if the task cannot be edited, so the editStatusBanner can be shown instead */}
            {canEdit ? unconfirmedProductsNotice : editStatusBanner}

            {instructions}

            {nameForm}

            {compareAtPriceTypeForm}

            {changeTypeSection}

            {addTagTypeForm}

            {canEdit && false ?
                <ProductSearch task={untouchedTask}
                               onAddToList={(p) => handleAddProductToTask(p)}/> : null}

            <PriceAdjustments disabled={!canEdit} key={taskLoadCount}
                              task={untouchedTask} paginationComponent={priceAdjustmentPagination}
                              searchComponent={searchPriceAdjustments}
                              loading={loadProductsOpt.loading}
                              handleLoadProducts={loadProducts}
            />
        </FormLayout>
    }

    const toastMarkup = hasErrorState.hasError ? (
        <Toast content={hasErrorState.content} error
               onDismiss={() => dispatchHasError({type: "CLOSE"})}
               duration={4500}/>
    ) : null;

    const primaryAction = {
        content: "Import From File",
        disabled: untouchedTask.status !== "ready" || untouchedTask.has_unconfirmed_products === true || untouchedTask.has_imported_products,
        accessibilityLabel: "Import product price changes from a CSV",
        onAction() {
            handleFileUploadClicked();
        }
    };

    const secondaryActions = [
        {
            content: "Do Rollback",
            accessibilityLabel: "Rolls back any price changes that occurred",
            disabled: !(untouchedTask.status === 'active' || (untouchedTask.status === 'completed' && untouchedTask.change_type === 'permanent')),
            onAction() {
                handleDoRollback(untouchedTask.id);
            }
        }
    ];

    secondaryActions.splice(1, 0, {
        content: "Download Sample File",
        accessibilityLabel: "Download a file in the format expected by the csv import function",
        url: `${window.location.protocol}//${window.location.host}/import-example.csv`,
    });

    // if (FlaggerInstance.isFlagEnabled("run_task_now")) {
    //     secondaryActions.splice(1, 0, {
    //         content: "Run Task Now",
    //         accessibilityLabel: "Runs the task immediately instead of waiting for the scheduled time",
    //         disabled: untouchedTask.status !== "ready",
    //         onAction() {
    //             handleRunTaskNow(untouchedTask.id);
    //         }
    //     })
    //
    // }

    return <Frame>
        {changeBar}
        <Page
            title={title}
            fullWidth
            breadcrumbs={[{
                content: 'Tasks',
                onAction() {
                    history.push("/");
                }
            }]}

            primaryAction={primaryAction}
            secondaryActions={secondaryActions}
        >
            <TitleBar title={untouchedTask ? untouchedTask.name : "Task"}
                      breadcrumbs={[{
                          content: "Tasks",
                          onAction() {
                              history.push("/");
                          }
                      }]}/>
            <div className="spacer"/>
            <input id="file-upload-input" onChange={handleUploadFileSelected} type="file"
                   name="file"
                   style={{display: "none"}}/>

            {body}
            {updateToast}
            {toastMarkup}
        </Page>
    </Frame>
}

export default TaskView;
