import {getLocation} from "@shopify/app-bridge/client/redirect";


function PostAuth() {
    const query = new URLSearchParams(getLocation().search);
    const host = query.get("host");

    localStorage.setItem('shopify-host', host);

    const redirectURL = `${window.location.protocol}//${window.location.host}/shopify/server-callback${getLocation().search}`;

    window.location.href = redirectURL;

    return null
}

export default PostAuth;
