import React from 'react';
import {Switch, Route, withRouter} from 'react-router'
import {useRoutePropagation} from '@shopify/app-bridge-react';
import CreateTask from "./pages/CreateTask";
import PostAuth from "./pages/PostAuth";
import TaskView from "./pages/TaskView";
import AuthRedirect from "./pages/AuthRedirect";

function Routes(props) {
    const {location} = props;

    useRoutePropagation(location);

    return <Switch>
        <Route path="/create-task">
            <CreateTask/>
        </Route>
        <Route path="/shopify/callback">
            <PostAuth/>
        </Route>
        <Route path="/tasks/:id">
            <TaskView/>
        </Route>
        <Route path="/">
            <AuthRedirect/>
        </Route>
    </Switch>
}

export default withRouter(Routes);