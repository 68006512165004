import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloProvider, InMemoryCache, split} from "@apollo/client";
import {WebSocketLink} from '@apollo/client/link/ws';

import '@shopify/polaris/build/esm/styles.css';
import {authenticatedFetch, getSessionToken} from "@shopify/app-bridge-utils";
import {Provider} from "@shopify/app-bridge-react";
import createApp from "@shopify/app-bridge";
import {getLocation} from "@shopify/app-bridge/client/redirect";
import {createUploadLink} from "apollo-upload-client";
import {getMainDefinition} from "@apollo/client/utilities";

const SHOPIFY_API_KEY = process.env.REACT_APP_SHOPIFY_API;

async function startup() {
    const query = new URLSearchParams(window.location.search);

    if ((query.get("host") === null && query.get("session") === null) && query.get("hmac") !== null) {
        window.location.href = `${window.location.protocol}//${window.location.host}/shopify/auth${getLocation().search}`;
        return
    } else {
        localStorage.setItem('shopify-host', query.get("host"));
    }

    let shopifyApp;

    const shopifyConfig = {
        apiKey: SHOPIFY_API_KEY,
        host: localStorage.getItem("shopify-host"),
    };

    try {
        shopifyApp = createApp(shopifyConfig);
    } catch (e) {
        console.log("Could not init shopify client due to missing host, hopefully this is okay", e);
    }

    const httpLink = createUploadLink({
        uri: `${window.location.protocol}//${window.location.host}/gql/mutate`,
        fetch: authenticatedFetch(shopifyApp)
    });

    const wsLink = new WebSocketLink({
        uri: `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/gql/mutate`,
        options: {
            reconnect: true,
            async connectionParams() {
                const token = await getSessionToken(shopifyApp);

                return {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            }
        }
    });

    const splitLink = split(
        ({query}) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    const client = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(),
    });

    const withShopifyHost = <React.StrictMode>
        <Provider config={shopifyConfig}>
            <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
        </Provider>
    </React.StrictMode>;

    const withoutShopifyHost = <React.StrictMode>
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    </React.StrictMode>;

    ReactDOM.render(shopifyConfig.host ? withShopifyHost : withoutShopifyHost,
        document.getElementById('root')
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}

startup();
