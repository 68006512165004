import React, {useEffect, useReducer, useState} from "react";
import {Button, Form, FormLayout, Frame, Heading, Page, Spinner, TextField, Toast} from "@shopify/polaris";
import {useHistory} from "react-router";
import {gql, useMutation} from "@apollo/client";
import validations from "../validations";
import {TitleBar} from "@shopify/app-bridge-react";

const CREATE_TASK = gql`
    mutation CreateTaskName($name: String!) {
        create_task_with_name(name: $name) {
            id
        }
    }`;

const ERROR_MSGS = {
    IS_REQUIRED: "Task name is required",
    INVALID_LENGTH: "Task name should be 2 - 45 characters",
};

const focusedElementReducer = (state, action) => {
    switch (action.type) {
        case 'focus':
            return true;
        case 'blur':
            return false;
        default:
            throw new Error("unknown action");
    }
};

function CreateTask() {
    const history = useHistory();

    const [name, setName] = useState("");
    const [hasError, setHasError] = useState(false);

    const [createTask, {loading}] = useMutation(CREATE_TASK, {
        variables: {
            name,
        },
        onCompleted({create_task_with_name: {id}}) {
            history.push(`/tasks/${id}`);
        },
        onError(e) {
            console.error(e);
            setHasError(true);
        },
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [taskNameFocusState, dispatchTaskNameFocus] = useReducer(focusedElementReducer, false, () => false);

    useEffect(() => {
        if (taskNameFocusState) {
            if (!validations.isRequired(name.trim())) {
                setErrorMsg(ERROR_MSGS.IS_REQUIRED);
            } else if (!validations.hasProperLength(name.trim())) {
                setErrorMsg(ERROR_MSGS.INVALID_LENGTH);
            } else {    // valid
                setErrorMsg("");
            }
        }
    }, [name, taskNameFocusState]);

    let body;

    if (loading) {
        body = <Spinner/>
    } else {
        body = <Form onSubmit={async () => {
            await createTask();
        }}>
            <FormLayout>
                <TextField
                    label="Name"
                    type="text"
                    autoComplete="off"
                    value={name}
                    onChange={setName}
                    onFocus={() => dispatchTaskNameFocus({type: "focus"})}
                    onBlur={() => dispatchTaskNameFocus({type: "blur"})}
                    helpText={
                        <span>
                        What should this task be called?
                    </span>
                    }
                    error={errorMsg}
                />
            </FormLayout>
            <div className="spacer"/>

            <Button disabled={errorMsg || !name} submit={true}>Create</Button>
        </Form>;
    }

    const toastMarkup = hasError ? (
        <Toast content="Failed to create task." error onDismiss={() => setHasError(false)} duration={4500}/>
    ) : null;

    return <Frame>
        <Page breadcrumbs={[{
            content: "Tasks",
            onAction: () => {
                history.push("/tasks");
            }
        }]}>
            <TitleBar title="Create Task" />
            <Heading>
                Create Task
            </Heading>

            <div className="spacer"/>

            {body}
            {toastMarkup}
        </Page>
    </Frame>
}

export default CreateTask;