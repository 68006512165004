import React, {useCallback, useState} from "react";
import {gql, useSubscription} from "@apollo/client";
import {Caption, ProgressBar, Spinner, Stack} from "@shopify/polaris";

const PRICE_CHANGE_PROGRESS = gql`
    subscription PriceChangeProgress($workflow_id: String!, $run_id: String!) {
        price_change_progress(run_id: $run_id, workflow_id: $workflow_id) {
            status
            friendly_status
            progress_percent
            failed
            acquiring_lock
            holds_lock
        }
    }`;

function PriceChangeStatus({workflow_id, run_id, onJobDone: onPriceChangeFinished}) {
    const onFinished = useCallback(() => {
        onPriceChangeFinished();
    }, [onPriceChangeFinished]);

    const [isDone, setIsDone] = useState(false);

    // TODO https://github.com/apollographql/react-apollo/issues/3577#issuecomment-583173418
    // TODO this will get rid of the memory leak when closing this component
    const {data, loading, error} = useSubscription(PRICE_CHANGE_PROGRESS, {
        variables: {
            workflow_id,
            run_id
        },

        fetchPolicy: 'network-only',

        onSubscriptionData({subscriptionData: {data}}) {
            if (isDone) {
                return
            }

            if (!data || !data.price_change_progress) {
                return
            }

            const price_change_progress = data.price_change_progress;

            if (price_change_progress && (price_change_progress.status === "finished" || price_change_progress.progress_percent === 100)) {
                onFinished();
                setIsDone(true);
            }
        },
    });

    let body;

    if (loading) {
        body = <Stack alignment="center">
            <Stack.Item>
                <Spinner size="small"/>
            </Stack.Item>
        </Stack>;
    } else if (error) {
        body = <p>Could not get the status of this task: {error.message}</p>
    } else {
        if (data && data.price_change_progress) {
            const {status, friendly_status, failed, progress_percent, acquiring_lock} = data.price_change_progress;

            const finished = status === "finished";

            body = <div>
                <Caption>{friendly_status}</Caption>
                <ProgressBar size="small" color={failed ? 'critical' : finished ? 'success' : 'primary'}
                             progress={progress_percent}/>
                {acquiring_lock ?
                    <div>More than one task is active right now! Other tasks must finish before this one can
                        proceed</div> : null}
            </div>
        } else {
            body = <div/>;
        }
    }

    return body;
}

export default PriceChangeStatus;