import {Badge, Icon, IndexTable, Stack, Tag, Thumbnail} from "@shopify/polaris";
import {ChevronDownMinor, ChevronUpMinor} from '@shopify/polaris-icons';
import React, {useCallback, useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import PriceAdjustmentEditPriceInput from "./PriceAdjustmentEditPriceInput";

function priceAdjustment({original_price_cents, altered_price_cents}) {
    const priceDifference = altered_price_cents - original_price_cents;

    if (priceDifference > 0) {
        return <Stack spacing="tight" alignment="center">
            <Stack.Item>
                <Icon backdrop source={ChevronUpMinor} color="success"/>
            </Stack.Item>
            <Stack.Item>
                <span>${(priceDifference / 100).toFixed(2)}</span>
            </Stack.Item>
        </Stack>;
    } else if (priceDifference < 0) {
        return <Stack spacing="tight" alignment="center">
            <Stack.Item>
                <Icon backdrop source={ChevronDownMinor} color="critical"/>
            </Stack.Item>
            <Stack.Item>
                <span>${(Math.abs(priceDifference) / 100).toFixed(2)}</span>
            </Stack.Item>
        </Stack>;
    }

    return <div>${(priceDifference / 100).toFixed(2)}</div>;
}

function PriceAdjustmentRow(args) {
    const {task, idx, doSaveAlteredPrice, disabled, selected} = args;
    const variant = args.variant;

    const debouncedSave = useDebouncedCallback(doSaveAlteredPrice, 500);

    const [centsPrice, setCentsPrice] = useState(args.variant.altered_price.price_cents);
    const [dollarPrice, setDollarPrice] = useState((args.variant.altered_price.price_cents / 100).toFixed(2));
    const [compareAtCentsPrice, setCompareAtCentsPrice] = useState(args.variant.altered_price.compare_at_price_cents);
    const [compareAtDollarPrice, setCompareAtDollarPrice] = useState((args.variant.altered_price.compare_at_price_cents / 100).toFixed(2));

    const onEditPriceFormChange = useCallback((value) => {
        const computedPrice = Math.round(parseFloat(value || "0") * 100);

        setDollarPrice(value);
        setCentsPrice(computedPrice);
        if (computedPrice > 0) {
            debouncedSave({
                ...variant,
                altered_price: {
                    ...variant.altered_price,
                    price_cents: computedPrice,
                    compare_at_price_cents: compareAtCentsPrice,
                }
            });
        }
    }, [compareAtCentsPrice, debouncedSave, variant]);

    const onCompareAtPriceChange = useCallback((value) => {
        const computedPrice = Math.round(parseFloat(value) * 100);

        setCompareAtDollarPrice(value);
        setCompareAtCentsPrice(computedPrice);

        if (computedPrice > 0) {
            debouncedSave({
                ...variant,
                altered_price: {
                    ...variant.altered_price,
                    cents_price: centsPrice,
                    compare_at_price_cents: computedPrice,
                }
            });
        }
    }, [centsPrice, debouncedSave, variant])

    return <IndexTable.Row id={variant.id} key={variant.id} position={idx} selected={selected}>
        {/* Image */}
        <IndexTable.Cell>
            <div className="image-container">
                <Thumbnail
                    source={variant.shopify_image_url ? variant.shopify_image_url : "https://cdn.shopify.com/s/images/admin/no-image-large.gif?da5ac9ca38617f8fcfb1ee46268f66d451ca66b4"}
                    alt={`Product image for ${variant.name}`}/>
            </div>
        </IndexTable.Cell>

        {/* Name */}
        <IndexTable.Cell>
            {variant.shopify_title}
        </IndexTable.Cell>

        {/* SKU */}
        <IndexTable.Cell>
            {variant.shopify_sku ? <Tag>{variant.shopify_sku}</Tag> : null}
        </IndexTable.Cell>

        {/* Barcode */}
        <IndexTable.Cell>
            {variant.shopify_barcode ? <Tag>{variant.shopify_barcode}</Tag> : null}
        </IndexTable.Cell>

        {/* Current Price */}
        <IndexTable.Cell>
            ${(variant.original_price.price_cents / 100).toFixed(2)}
        </IndexTable.Cell>

        {/* New Price */}
        <IndexTable.Cell>
            <span>${(variant.altered_price.price_cents / 100).toFixed(2)}</span>
        </IndexTable.Cell>

        {/* Inventory */}
        <IndexTable.Cell>
            {variant.inventory_amount}
        </IndexTable.Cell>

        {/* Compare At Price */}
        <IndexTable.Cell>
            {
                task.compare_at_price_mode === "set-manually"
                    ?
                    <PriceAdjustmentEditPriceInput
                        label={"Compare At Price"}
                        disabled={disabled}
                        onPriceChange={onCompareAtPriceChange}
                        value={compareAtDollarPrice}
                    />
                    :
                    <div>
                        {
                            task.compare_at_price_mode === "clear" ? <Badge>Clear</Badge> :
                                <span>${(variant.altered_price.compare_at_price_cents / 100).toFixed(2)}</span>
                        }
                    </div>
            }
        </IndexTable.Cell>

        {/* Price Adjustment */}
        <IndexTable.Cell>
            {priceAdjustment({
                original_price_cents: variant.original_price.price_cents,
                altered_price_cents: centsPrice,
            })}
        </IndexTable.Cell>
    </IndexTable.Row>
}

export default PriceAdjustmentRow;