import {Badge, EmptyState, IndexTable, Tag, useIndexResourceState} from "@shopify/polaris";
import React from "react";

function ReviewProductsTableRow({variant, idx, selected}) {

    const originalPrice = (variant.original_price.price_cents / 100).toFixed(2);
    const alteredPrice = (variant.altered_price.price_cents / 100).toFixed(2);
    const priceDiffCents = (variant.altered_price.price_cents - variant.original_price.price_cents);

    const compareAtPrice = (variant.altered_price.compare_at_price_cents / 100).toFixed(2)

    let priceDifference;

    if (priceDiffCents < 0) {
        priceDifference = <Badge status="success">{(priceDiffCents / 100.0).toFixed(2)}</Badge>
    } else if (priceDiffCents > 0) {
        priceDifference = <Badge status="warning">+{(priceDiffCents / 100.0).toFixed(2)}</Badge>
    } else {
        priceDifference = <Badge>No Change</Badge>;
    }

    return <IndexTable.Row id={variant.id} key={variant.id} position={idx} selected={selected}>
        {/* Image */}
        {/*<IndexTable.Cell>*/}
        {/*    <div className="image-container">*/}
        {/*        <Thumbnail*/}
        {/*            source={variant.shopify_image_url ? variant.shopify_image_url : "https://cdn.shopify.com/s/images/admin/no-image-large.gif?da5ac9ca38617f8fcfb1ee46268f66d451ca66b4"}*/}
        {/*            alt={`Product image for ${variant.name}`}/>*/}
        {/*    </div>*/}
        {/*</IndexTable.Cell>*/}

        {/* Import Line # */}
        <IndexTable.Cell>
            {variant.import_index}
        </IndexTable.Cell>

        {/* Name */}
        <IndexTable.Cell>
            {variant.shopify_title}
        </IndexTable.Cell>

        {/* SKU */}
        <IndexTable.Cell>
            {
                variant.shopify_sku ? <Tag>{variant.shopify_sku}</Tag> : null
            }
        </IndexTable.Cell>

        {/* Barcode */}
        <IndexTable.Cell>
            {
                variant.shopify_barcode ? <Tag>{variant.shopify_barcode}</Tag> : null
            }
        </IndexTable.Cell>

        {/* Current Price Difference */}
        <IndexTable.Cell>
            <s style={{
                marginRight: 5
            }}>${originalPrice}</s>
            <span style={{
                marginRight: 5,
                fontWeight: "bold"
            }}>${alteredPrice}</span>
            {priceDifference}
        </IndexTable.Cell>

        {/* Compare At Price Difference */}
        <IndexTable.Cell>
            ${compareAtPrice}
        </IndexTable.Cell>

        {/* Quantity */}
        <IndexTable.Cell>
            {variant.inventory_amount}
        </IndexTable.Cell>
    </IndexTable.Row>
}

function ReviewProductsTable({task_id, products, loading, handleConfirm, handleDelete}) {
    const headings = [
        {title: "Name"},
        {title: "SKU"},
        {title: "Barcode"},
        {title: "Current Price"},
        {title: "Compare At Price"},
        {title: "Inventory"},
    ];

    headings.unshift({title: "Import Line #"});

    // if (FlaggerInstance.isFlagEnabled("review_products_images")) {
    //     headings.unshift({title: ""})
    // }

    const emptyState = <EmptyState
        heading="Nothing here yet"
        image="data:image/svg+xml,%3csvg width='60' height='60' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M41.87 24a17.87 17.87 0 11-35.74 0 17.87 17.87 0 0135.74 0zm-3.15 18.96a24 24 0 114.24-4.24L59.04 54.8a3 3 0 11-4.24 4.24L38.72 42.96z' fill='%238C9196'/%3e%3c/svg%3e"
    >
        <p>There are no products</p>
    </EmptyState>;

    // variants
    let variants = products;

    const {selectedResources, allResourcesSelected, handleSelectionChange} = useIndexResourceState(variants);

    const promotedBulkActions = [
        {
            content: selectedResources.length > 1 ? "Confirm Product" : "Confirm Products",
            onAction: async () => {
                try {
                    await handleConfirm({
                        variables: {
                            task_id: task_id,
                            product_ids: selectedResources,
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
                handleSelectionChange("all", false);
            },
        },
        {
            content: selectedResources.length > 1 ? "Delete Product" : "Delete Products",
            onAction: async () => {
                try {
                    await handleDelete({
                        variables: {
                            task_id: task_id,
                            product_ids: selectedResources,
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
                handleSelectionChange("all", false);
            },
        }
    ];

    const rowMarkups = variants.map((product, idx) => {
        return <ReviewProductsTableRow
            key={product.id}
            variant={product}
            idx={idx}
            selected={selectedResources.includes(product.id)}
        />
    });

    return <IndexTable
        resourceName={{singular: "Product", plural: "Products"}}
        headings={headings}
        itemCount={products.length}
        emptyState={emptyState}
        loading={loading}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={promotedBulkActions}
        selectable={false}
    >
        {rowMarkups}
    </IndexTable>
}

export default ReviewProductsTable;
