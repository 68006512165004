import {Icon, Modal, Select, TextField, Toast} from "@shopify/polaris";
import {SearchMajor} from "@shopify/polaris-icons";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import ReviewProductsTable from "./ReviewProductsTable";
import ListPagination from "./ListPagination";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {useDebounce} from "use-debounce";

const LIST_TASK_PRODUCTS = gql`
    query ListTaskProducts($id: String!, $limit: Int, $offset: Int, $query: ProductSearch) {
        task_by_id(id: $id) {
            id
            products (limit: $limit, offset: $offset, query: $query) {
                products {
                    id
                    shopify_id
                    shopify_title
                    confirmed
                    parent_product_id
                    shopify_image_url
                    shopify_barcode
                    shopify_sku
                    inventory_amount
                    import_index

                    original_price {
                        price_cents
                        price_context
                        compare_at_price_cents
                        currency_iso_code
                    }

                    altered_price {
                        price_cents
                        price_context
                        compare_at_price_cents
                        currency_iso_code
                    }
                }

                total_count
            }
        }
    }`;

const DELETE_PRODUCT = gql`
    mutation DeleteProduct($task_id: String!, $product_ids: [String!]!){
        delete_products_from_task(task_id: $task_id, product_ids: $product_ids)
    }`;

const CONFIRM_PRODUCT = gql`
    mutation DeleteProduct($task_id: String!, $product_ids: [String!]!){
        confirm_products(task_id: $task_id, product_ids: $product_ids)
    }`;


const REVIEW_PRODUCTS_PAGE_LIMIT = 30;
const ERROR_TYPES = {
    LOAD_PRODUCTS_ERROR: "load_products_error",
    DELETE_PRODUCTS_ERROR: "delete_products_error",
    CONFIRM_PRODUCTS_ERROR: "confirm_products_error",
};
const ERROR_MSGS = {
    LOAD_PRODUCTS_ERROR: "Failed to load products for review",
    DELETE_PRODUCTS_ERROR: "Failed to delete selected products",
    CONFIRM_PRODUCTS_ERROR: "Failed to confirm selected products",
}

const calculateItemNumber = (pageNumber, offset, listLength) => {
    let itemNumber = 0;
    if (offset === 0) {
        itemNumber = listLength;
    } else {
        itemNumber = (pageNumber - 1) * REVIEW_PRODUCTS_PAGE_LIMIT + listLength;
    }
    return itemNumber;
};

const hasErrorReducer = (state, action) => {
    switch (action.type) {
        case ERROR_TYPES.LOAD_PRODUCTS_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.LOAD_PRODUCTS_ERROR,
            }
        }
        case ERROR_TYPES.DELETE_PRODUCTS_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.DELETE_PRODUCTS_ERROR,
            }
        }
        case ERROR_TYPES.CONFIRM_PRODUCTS_ERROR: {
            return {
                hasError: true,
                content: ERROR_MSGS.CONFIRM_PRODUCTS_ERROR,
            }
        }
        case "CLOSE": {
            return {
                hasError: false,
                content: "",
            }
        }
        default: {
            throw new Error("unknown hasError reducer action");
        }
    }
};

function ReviewProductsModal({
                                 task_id,
                                 active,
                                 handleClose,
                                 handleConfirmAllProducts,
                                 handleReloadTask,
                                 handleReloadTaskApproveAll
                             }) {
    // states
    const [searchQuery, setSearchQuery] = useState("");
    const [skuSearchQuery, setSKUSearchQuery] = useState("");
    const [confirmedSelect, setConfirmedSelect] = useState("unconfirmed");
    const [isUnConfirmedFilter, setIsUnConfirmedFilter] = useState(true);
    const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
    const [debouncedSKUQuery] = useDebounce(skuSearchQuery, 500);
    const [offset, setOffset] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsItemNumber, setProductsItemNumber] = useState(0);
    const [productsCount, setProductsCount] = useState(0);
    const [productsPageNumber, setProductsPageNumber] = useState(1);

    const [hasErrorState, dispatchHasError] = useReducer(hasErrorReducer,
        {hasError: false, content: ""},
        () => ({hasError: false, content: ""}));

    // queries
    const [loadProducts, loadProductsOpt] = useLazyQuery(LIST_TASK_PRODUCTS, {
        variables: {
            id: task_id,
            limit: REVIEW_PRODUCTS_PAGE_LIMIT,
            offset: offset,
            query: {
                unconfirmed_only: isUnConfirmedFilter,
                title_contains: debouncedSearchQuery,
                sku_contains: debouncedSKUQuery
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted({task_by_id}) {
            const fetchedProducts = task_by_id.products.products ? task_by_id.products.products : [];

            setProducts(fetchedProducts);

            setProductsItemNumber(calculateItemNumber(
                productsPageNumber, offset, task_by_id.products.products.length
            ));

            setProductsCount(task_by_id.products.total_count);
        },
        onError(e) {
            console.error(e);
            dispatchHasError({type: ERROR_TYPES.LOAD_PRODUCTS_ERROR})
        },
    });

    // mutations
    const [deleteProduct] = useMutation(DELETE_PRODUCT, {
        onCompleted: () => {
            loadProducts();
            handleReloadTask();
        },
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: ERROR_TYPES.DELETE_PRODUCTS_ERROR});
            loadProducts();
            handleReloadTask();
        }
    });

    const [confirmProduct] = useMutation(CONFIRM_PRODUCT, {
        onCompleted: () => {
            loadProducts();
            handleReloadTask();
        },
        onError: (e) => {
            console.error(e);
            dispatchHasError({type: ERROR_TYPES.CONFIRM_PRODUCTS_ERROR});
            loadProducts();
            handleReloadTask();
        }
    });

    // callbacks
    const handleConfirmFilterChange = useCallback((value) => {
        setConfirmedSelect(value);
    }, [setConfirmedSelect]);

    const handleProductsNextPage = useCallback(() => {
        setOffset(offset + REVIEW_PRODUCTS_PAGE_LIMIT);
        loadProducts();
        setProductsPageNumber(productsPageNumber + 1);
    }, [loadProducts, offset, productsPageNumber]);

    const handleProductsPrevPage = useCallback(() => {
        if (offset >= REVIEW_PRODUCTS_PAGE_LIMIT) {
            setOffset(offset - REVIEW_PRODUCTS_PAGE_LIMIT);
        } else {
            setOffset(0);
        }
        loadProducts();
        setProductsPageNumber(productsPageNumber - 1);
    }, [loadProducts, offset, productsPageNumber]);

    const handleApproveAllProducts = async () => {
        await handleConfirmAllProducts(task_id);
        handleClose();
        await handleReloadTaskApproveAll();
    };

    // load products on modal open
    useEffect(async () => {
        if (active) {
            await loadProducts();
        } else {
            setProducts([]);
        }
    }, [active]);

    // filter products confirmed
    useEffect(() => {
        if (confirmedSelect === "confirmed") {
            setIsUnConfirmedFilter(false);
        } else {
            setIsUnConfirmedFilter(true);
        }
    }, [confirmedSelect]);

    useEffect(async () => {
        setOffset(0);
        setProductsPageNumber(1);
        await loadProducts();
    }, [isUnConfirmedFilter]);

    // on debounced search
    useEffect(async () => {
        setOffset(0);
        setProductsPageNumber(1);
        await loadProducts();
    }, [debouncedSearchQuery]);

    // search
    const searchByNameComponent = <TextField
        labelHidden label={"Search price adjustments"} autoComplete="off"
        onChange={(v) => {
            setSearchQuery(v);
            setSKUSearchQuery("");
        }}
        value={searchQuery}
        prefix={<Icon source={SearchMajor}/>}
        placeholder={"Filter By Name"}
    />

    // search sku
    const searchBySkuComponent = <TextField
        labelHidden label={"Search price adjustments by sku"} autoComplete="off"
        onChange={(v) => {
            setSKUSearchQuery(v);
            setSearchQuery("");
        }}
        value={skuSearchQuery}
        prefix={<Icon source={SearchMajor}/>}
        placeholder={"Filter By SKU"}
    />

    // filters
    const confirmedFilterOptions = [
        {label: "Confirmed Products", value: "confirmed"},
        {label: "Non-Confirmed Products", value: "unconfirmed"},
    ]
    const confirmedFilter = <Select
        labelHidden
        options={confirmedFilterOptions}
        value={confirmedSelect}
        onChange={handleConfirmFilterChange}
    />;

    // pagination
    const hasPrevPage = offset > 0;
    const hasNextPage = productsItemNumber < productsCount;
    const paginationComponent = <ListPagination
        hasNext={hasNextPage}
        hasPrevious={hasPrevPage}
        itemNumber={productsItemNumber}
        count={productsCount}
        handleNext={handleProductsNextPage}
        handlePrevious={handleProductsPrevPage}
        pageNumber={productsPageNumber}
    />

    // table
    const productsTable = <ReviewProductsTable
        task_id={task_id}
        products={products}
        loading={loadProductsOpt.loading}
        handleConfirm={confirmProduct}
        handleDelete={deleteProduct}
    />

    // toasted message
    const toastMarkup = hasErrorState.hasError ? (
        <Toast content={hasErrorState.content} error onDismiss={() => dispatchHasError({type: "CLOSE"})}
               duration={4500}/>
    ) : null;

    return <Modal
        large
        open={active}
        onClose={() => handleClose()}
        title={"Review Imported Products"}
        primaryAction={{
            content: "Approve All",
            onAction: handleApproveAllProducts,
        }}
        secondaryActions={[{
            content: "Cancel",
            onAction: () => handleClose(),
        }]}
    >
        <Modal.Section>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1, paddingRight: 5}}>
                    {searchByNameComponent}
                </div>
                <div style={{flex: 1}}>
                    {searchBySkuComponent}
                </div>


                {/*<div style={{paddingLeft: '0.4rem'}}>*/}
                {/*    {confirmedFilter}*/}
                {/*</div>*/}
            </div>
            {productsTable}
            {paginationComponent}
            {toastMarkup}
        </Modal.Section>
    </Modal>
}

export default ReviewProductsModal;
