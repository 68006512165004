import React, {useEffect, useReducer, useState} from "react";
import {TextField} from "@shopify/polaris";
import validations from "../validations";

const VALIDATION_ERROR_MSGS = {
    INVALID_MONEY: "Price should be larger than $0.00",
    IS_SMALL_MONEY: "Did you accidentally miss typed the price?"
};

const focusedElementReducer = (state, action) => {
    switch (action.type) {
        case 'focus':
            return true;
        case 'blur':
            return false;
        default:
            throw new Error("unknown action");
    }
};

function PriceAdjustmentEditPriceInput({label, disabled, onPriceChange, value}) {
    const [onFocusState, dispatchOnFocusState] = useReducer(focusedElementReducer, false, () => false);
    const [errorMsg, setErrorMsg] = useState("");
    const [helpText, setHelpText] = useState("");

    useEffect(() => {
        if (onFocusState) {
            if (!validations.isMoney(value)) {
                setHelpText("");
                setErrorMsg(VALIDATION_ERROR_MSGS.INVALID_MONEY);
            } else if (validations.isSmallMoney(value)) {
                setErrorMsg("");
                setHelpText(VALIDATION_ERROR_MSGS.IS_SMALL_MONEY);
            } else { //valid
                setErrorMsg("");
                setHelpText("");
            }
        }
    }, [onFocusState, value]);

    return <div style={{minWidth: '125px'}}><TextField
        autoComplete="off"
        label={label}
        disabled={disabled}
        prefix="$"
        type="number"
        step=".01"
        onChange={onPriceChange}
        value={value}
        labelHidden
        helpText={helpText}
        error={errorMsg}
        onFocus={() => dispatchOnFocusState({ type: "focus" })}
        onBlur={() => dispatchOnFocusState({ type: "blur" })}
    /></div>;
}

export default PriceAdjustmentEditPriceInput;