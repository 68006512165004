import {getLocation} from "@shopify/app-bridge/client/redirect";
import TaskList from "./TaskList";
import {TitleBar} from "@shopify/app-bridge-react";


function AuthRedirect() {
    const query = new URLSearchParams(getLocation().search);

    if ((query.get("host") === null || query.get("session") === null) && query.get("hmac") !== null) {
        window.location.href = `${window.location.protocol}//${window.location.host}/shopify/auth${getLocation().search}`;
        return null;
    }

    return <div>
        <TitleBar title="Tasks"/>
        <TaskList/>
    </div>
}

export default AuthRedirect;
