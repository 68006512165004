import React, {useCallback, useState} from "react";
import {Banner, Button, Select, Stack, TextField} from "@shopify/polaris";
import './SearchRule.css';
import {gql, useQuery} from "@apollo/client";

const GET_COLLECTIONS = gql`
    query GetCollections {
        shopify_collections {
            id
            name

            rule_set {
                appliedDisjunctively
                rules {
                    column
                    condition
                    relation
                }
            }
        }
    }`;

function newEmptyRule() {
    return {
        field: 'title',
        type: '_contains',
        value: ''
    }
}

function convertShopifyRule(shopifyRuleSet) {
    const subRules = [];
    const warnings = [];

    shopifyRuleSet.rules.forEach(rule => {
        const subRule = {};

        switch (rule.column) {
            case "IS_PRICE_REDUCED":
                subRule.field = "is_price_reduced"
                break;
            case "TAG":
                subRule.field = "tag"
                break;
            case "TITLE":
                subRule.field = "title"
                break;
            case "TYPE":
                subRule.field = "type"
                break;
            case "VARIANT_COMPARE_AT_PRICE":
                warnings.push("A collection you have chosen uses the variant compare at price filter, which is not currently supported. Some incorrect products may be returned");
                break;
            case "VARIANT_INVENTORY":
                subRule.field = "inventory_total";
                break;
            case "VARIANT_PRICE":
                warnings.push("A collection you have chosen uses the variant price as a filter. This filter is not fully supported. Some incorrect products may appear")
                subRule.field = "price";
                break;
            case "VARIANT_TITLE":
                warnings.push("A collection you have chosen uses the variant title as a filter. This filter is not fully supported. Some incorrect products may appear")
                subRule.field = "title";
                break;
            case "VARIANT_WEIGHT":
                warnings.push("A collection you have chosen uses the variant weight as a filter. This filter is not fully supported. Some incorrect products may appear")
                break;
            case "VENDOR":
                subRule.field = "vendor";
                break;
            default:
                warnings.push(`A collection you have chosen filters by ${rule.column}. This is not currently supported. Some incorrect products may appear`);
        }

        // If the above code hasn't set a field, cancel the operation
        if (!subRule.field) {
            return
        }

        switch (rule.relation) {
            case "CONTAINS":
                subRule.type = "_contains";
                break;
            case "ENDS_WITH":
                subRule.type = "_ends_with"
                break;
            case "EQUALS":
                subRule.type = "_eq"
                break;
            case "GREATER_THAN":
                subRule.type = "_gt"
                break;
            case "IS_NOT_SET":
                subRule.type = "_is_not_set"
                break;
            case "IS_SET":
                subRule.type = "_is_set"
                break;
            case "LESS_THAN":
                subRule.type = "_lt"
                break;
            case "NOT_CONTAINS":
                subRule.type = "_not_contains"
                break;
            case "NOT_EQUALS":
                subRule.type = "_neq"
                break;
            case "STARTS_WITH":
                subRule.type = "_starts_with"
                break;
            default:
        }

        if (!isNaN(parseFloat(rule.condition))) {
            subRule.value = parseFloat(rule.condition)
        } else {
            subRule.value = rule.condition;
        }

        subRules.push(subRule);
    });

    return {subRules, warnings};
}

function SearchRule({rule, idx, setRules, onRemove, singleCollectionOnlySearch}) {

    const {data, loading, error} = useQuery(GET_COLLECTIONS);

    const [warnings, setWarnings] = useState([]);

    // **************************
    // * RULE FIELD SELECTOR
    // **************************
    // Called when a rule field changes, ie. title, collection, etc..
    const handleRuleFieldChange = useCallback((value) => {
        setRules((oldRules) => {
            let type;
            let newv;

            if (value === "collection") {
                type = "_eq";

                if (data && data.shopify_collections && data.shopify_collections.length > 0) {
                    newv = data.shopify_collections[0].id;
                } else {
                    newv = null;
                }
            } else if (value === "price") {
                type = "_eq";
                newv = null;
            } else if (value === "tag") {
                type = "_eq";
                newv = null;
            } else if (value === "status") {
                type = "_eq";
                newv = "ACTIVE";
            } else if (value === "published_status") {
                type = "_eq";
                newv = "published";
            } else {
                type = "_eq";
                newv = null;
            }

            oldRules[idx] = {
                ...rule,
                field: value,
                type: type,
                value: newv,
            }

            return [...oldRules];
        })
    }, [data, idx, rule, setRules]);

    const ruleFieldOptions = [
        {label: "Barcode", value: 'barcode'},
        {label: "Price", value: 'price'},
        {label: "Type", value: 'type'},
        {label: "Published Status", value: 'published_status'},
        {label: "SKU", value: 'sku'},
        {label: "Status", value: 'status'},
        {label: "Tag", value: 'tag'},
        {label: "Title", value: 'title'},
        {label: "Collection", value: 'collection'}
    ];

    const ruleFieldSelector = <Select options={ruleFieldOptions} onChange={handleRuleFieldChange} value={rule.field}
                                      labelHidden label="Rule Field"/>;

    // **************************
    // * RULE TYPE SELECTOR
    // **************************
    // Called when the rule type changes, ie. _eq, _contains, etc...
    const handleRuleTypeChange = useCallback((value) => {
        setRules((oldRules) => {
            oldRules[idx] = {
                ...rule,
                type: value,
            }

            return [...oldRules];
        });
    }, [idx, rule, setRules]);

    const numericConditionOptions = [
        {label: "Equals", value: '_eq'},
        {label: "Greater Than", value: '_gt'},
        {label: "Greater Than or Equal To", value: '_gte'},
        {label: "Less Than", value: '_lt'},
        {label: "Less Than or Equal To", value: '_lte'},
        // {label: "Is One Of", value: '_in'},
    ];

    const stringConditionOptions = [
        {label: "Equals", value: '_eq'},
        {label: "Contains", value: '_contains'},
        // {label: "Greater Than", value: '_gt'},
        // {label: "Greater Than or Equal To", value: '_gte'},
        // {label: "Less Than", value: '_lt'},
        // {label: "Less Than or Equal To", value: '_lte'},
        // {label: "Is One Of", value: '_in'},
    ];

    const statusConditionOptions = [
        {label: "Equals", value: '_eq'},
        // {label: "Is One Of", value: '_in'},
    ];

    const publishedStatusConditionOptions = [
        {label: "Equals", value: '_eq'},
    ];

    const tagConditionOptions = [
        {label: "Equals", value: '_eq'},
        // {label: "Is One Of", value: '_in'},
    ];

    const productStatusOptions = [
        {label: "Active", value: 'ACTIVE'},
        {label: "Draft", value: 'DRAFT'},
    ];

    const publishedStatusOptions = [
        {label: "Published", value: 'published'},
        {label: "Unpublished", value: 'unpublished'},
    ];

    const collectionsTypeOptions = [
        {label: "Is", value: "_eq"}
    ];

    let collectionsSelectOptions;

    if (data && !loading && !error) {
        collectionsSelectOptions = data.shopify_collections.map(c => ({
            label: c.name,
            value: c.id,
        }));
    } else {
        collectionsSelectOptions = [
            {
                label: "Loading...",
                value: null,
            }
        ]
    }

    let ruleTypeOptions;

    switch (rule.field) {
        case "status":
            ruleTypeOptions = statusConditionOptions;
            break;
        case "published_status":
            ruleTypeOptions = publishedStatusConditionOptions;
            break;
        case "tag":
            ruleTypeOptions = tagConditionOptions;
            break;
        case "collection":
            ruleTypeOptions = collectionsTypeOptions;
            break;
        case "price":
            ruleTypeOptions = numericConditionOptions;
            break;
        default:
            ruleTypeOptions = stringConditionOptions;
    }

    const ruleTypeSelector = <Select options={ruleTypeOptions} onChange={handleRuleTypeChange} value={rule.type}
                                     labelHidden label="Rule Type"/>;

    let ruleInput;

    // Called when the filter rule changes, ie. collection id, title text, etc...
    const handleRuleValueChange = useCallback((value, isNumber) => {
        setRules((oldRules) => {
            let subRules;
            let collectionSearchMode;

            if (value && oldRules[idx].field === "collection" && data && data.shopify_collections && data.shopify_collections.length > 0) {
                const collectionRule = data.shopify_collections.find(c => c.id === value);

                if (collectionRule.rule_set.appliedDisjunctively) {
                    collectionSearchMode = "MATCH_ANY";
                } else {
                    collectionSearchMode = "MATCH_ALL";
                }

                const convertedRules = convertShopifyRule(collectionRule.rule_set);

                subRules = convertedRules.subRules;
                setWarnings(convertedRules.warnings);
            }

            let processedValue;

            if (!isNaN(parseFloat(value)) && isNumber === true) {
                processedValue = parseFloat(value);
            } else {
                processedValue = value;
            }

            oldRules[idx] = {
                ...rule,
                value: processedValue,
                subRules,
                collectionSearchMode,
            }

            return [...oldRules];
        })
    }, [data, idx, rule, setRules]);

    switch (rule.field) {
        case "status":
            ruleInput =
                <Select label="Status" labelHidden value={rule.value} options={productStatusOptions}
                        onChange={handleRuleValueChange}/>
            break;
        case "published_status":
            ruleInput =
                <Select label="Published Status" labelHidden value={rule.value} options={publishedStatusOptions}
                        onChange={handleRuleValueChange}/>
            break;
        case "collection":
            ruleInput = <Select label="Collection" labelHidden value={rule.value} options={collectionsSelectOptions}
                                onChange={(v) => handleRuleValueChange(v, false)}/>
            break;
        case "price":
            ruleInput = <TextField autoComplete="off" label="rule" type="number" labelHidden={true}
                                   value={rule.value ? rule.value.toString() : ''}
                                   onChange={(v) => handleRuleValueChange(v, true)}/>
            break;
        default:
            ruleInput = <TextField type="text" autoComplete="off" label="rule" labelHidden={true}
                                   value={rule.value ? rule.value.toString() : ''}
                                   onChange={(v) => handleRuleValueChange(v, false)}/>
    }

    return <div>
        {warnings.length > 0 && !singleCollectionOnlySearch ?
            <div className="mb">
                <Banner status="warning" title="Search Warnings">
                    <ul>
                        {warnings.map((w, i) => <li key={i}>{w}</li>)}
                    </ul>
                </Banner>
            </div>
            : null}
        <Stack>
            <Stack.Item>
                {ruleFieldSelector}
            </Stack.Item>
            <Stack.Item>
                {ruleTypeSelector}
            </Stack.Item>
            <Stack.Item fill>
                {ruleInput}
            </Stack.Item>
            <Stack.Item>
                <Button destructive onClick={() => onRemove()}>Remove</Button>
            </Stack.Item>
        </Stack>
    </div>
}

export default SearchRule;
export const NewEmptyRule = newEmptyRule;