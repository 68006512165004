import React from "react";
import { Pagination } from "@shopify/polaris";

function ListPagination({ hasPrevious, hasNext, handlePrevious, handleNext, pageNumber, itemNumber, count }) {
    return <div style={{display: "flex", alignItems: "center"}}>
        <Pagination hasPrevious={hasPrevious} hasNext={hasNext} onPrevious={handlePrevious} onNext={handleNext}
                    label={
                        <span>{pageNumber}</span>
                    }
                    style={{flex: 1}}
        />
        <div style={{paddingLeft: '0.8rem'}}>
            {itemNumber} / {count}
        </div>
    </div>

}

export default ListPagination;
