const hasProperLength = (str = "") => {
    if (!str) {
        return true;
    }
    const length = str.trim().length;
    return length <= 42 && length >= 2;
};

const isRequired = (str = "") => {
    return str !== "";
};

const isMoney = (str = "0.00") => {
    return parseFloat(str) >= 0.00;
};

const isSmallMoney = (str = "0.00") => {
    return parseFloat(str) < 1.00
};

const isNegative = (str = "0.00") => {
    return parseFloat(str) < 0
};

const isLessThan = (str = "0.00", val = 100.0) => {
    return parseFloat(str) < val
}

module.exports = {
    hasProperLength,
    isRequired,
    isMoney,
    isSmallMoney,
    isNegative,
    isLessThan
};